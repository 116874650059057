.topnav {
    background: #FC3C00 !important;
    flex: unset !important;
    padding: 4px 0px;
}

.top-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav-left-container {
    display: flex;
    align-items: center;
}


/* .econtainer {
    max-width: 1500px;
} */

.main-container {
    display: flex;
    /* height: 50px; */
    padding-top: 8px;
    justify-content: space-between;
    color: rgb(229, 226, 226);
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.topnav-left-wrapper {
    display: flex;
    align-items: center;
}


/* .pcontainer{
   
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    border-left: 30px solide green;
    
    
  } */

.phone {
    margin-right: 230px;
    display: flex;
    color: rgb(255, 255, 255);
    font-size: 15px;
    align-items: center;
}

.phoneicon {
    margin-right: 5px;
    margin-top: 2;
}

.phone a {
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
    font-family: "bahnschrift";
}

.phone a:hover {
    color: #ff3c00;
}


/* .econtainer{
  
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    
  } */

.email {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    color: rgb(255, 255, 255);
    font-size: 15px;
}

.email a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 15px;
}

.email a:hover {
    color: rgb(0, 0, 0);
}

.fb {
    size: 20px;
    height: 30px;
    width: 20px;
    margin-right: 20px;
    color: green;
    margin-left: 10px;
}

.insta {
    size: 20px;
    height: 30px;
    width: 30px;
    margin-right: 20px;
}

.wts {
    size: 20px;
    height: 30px;
    width: 30px;
    margin-right: 50px;
}

.our_social {
    display: flex;
    margin-left: 10px;
}

.social-icons-top {
    display: flex;
    font-size: 20px;
    margin-left: 40px;
}

.social-links a {
    display: inline-block;
    height: 37px;
    width: 37px;
    background-color: #ff3c00;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
    margin-left: 7px;
}

.social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}


/* .acontainer{
   
    background-color: rgb(194, 194, 194);
    width: 25%;
    margin-bottom: 5px;
    
    
  }
  .scontainer{
   
    background-color: rgb(2, 2, 2);
    width: 25%;
    margin-bottom: 5px;
    
    
  } */

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .topnav {
        display: none;
    }
}