/* Sidebar Styling */
.sidebar {
  width: 220px;
  height: calc(100vh - 60px); /* Adjust height to exclude Topbar */
  background-color: #343a40;
  position: fixed;
  top: 60px; /* Start Sidebar right below the Topbar */
  left: 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  transition: width 0.3s ease-in-out;
  z-index: 500; /* Ensures Sidebar stays below the Topbar */
}

.sidebar.collapsed {
  width: 80px;
}

.sidebarWrapper {
  padding: 20px;
  overflow-y: auto;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 1.2rem;
  color: #adb5bd;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: opacity 0.3s ease-in-out, margin 0.3s ease-in-out;
}

.sidebar.collapsed .sidebarTitle {
  opacity: 0;
  margin: 0; /* Removes extra spacing in collapsed view */
}

.sidebarList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarListItem {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #e9ecef;
  font-size: 1rem;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.sidebarListItem:hover {
  background-color: #495057;
  color: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.sidebarIcons {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #adb5bd;
  transition: color 0.3s ease, transform 0.3s ease;
}

.sidebarListItem:hover .sidebarIcons {
  color: #1abc9c;
  transform: scale(1.1);
}

.sidebar.collapsed .sidebarIcons {
  margin-right: 0; /* Center icon in collapsed view */
}

.sidebar.collapsed .sidebarListItem {
  justify-content: center; /* Center text and icons when collapsed */
}

.sidebarListItem a {
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: color 0.3s ease;
}

.collapseButton {
  background-color: #1abc9c;
  color: #ffffff;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: auto;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.collapseButton:hover {
  background-color: #16a085;
  transform: scale(1.05);
}

/* Adjustments for Collapsed Sidebar */
.sidebar.collapsed .sidebarListItem a {
  justify-content: center; /* Center link contents in collapsed state */
}

.sidebar.collapsed .sidebarWrapper {
  padding: 10px; /* Reduce padding when collapsed */
}
