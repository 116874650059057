/* video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
} */

.hero-container {
  background: url("/images/slider/slide1.png") no-repeat;
  background-size: 100% 100%;
  height: 40vh;
  width: 100%;
  border-bottom-left-radius: 250% 160px;
  border-bottom-right-radius: 250% 160px;
  text-align: left;
  padding: 17px 59px;
}

.hero-container > h1 {
  color: #fff;
  font-size: 40px;
  margin-top: -200px;
  /* margin-right: 800px; */
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  /* margin-right: 765px; */
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
}

@media only screen and (max-width: 1920px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 30px;
    /* margin-left: 40px; */
  }
  .hero-container > p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .hero-container h1 {
    font-size: 24px !important;
    margin-top: -90px;
  }
  .hero-container {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
  /* .hero-container {
    display: none;
  }
   */
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .hero-container {
    height: 40vh;
  }
}

h1 {
  text-align: left !important;
}

.custom-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .custom-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .custom-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .custom-container {
    width: 1170px;
  }
}

.text-white {
  color: white;
}

.fly-tag {
  background-color: #4052f3;
  border-radius: 21px;
  color: white;
  font-weight: 800;
  font-size: 14px;
  padding: 3px 17px;
}

h1 {
  margin-top: unset;
  margin-top: 5px;
  font-size: 60px;
}

.mt-60 {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .fly-tag {
    display: none;
  }
}
/* this is a form section*/
