* {
  font-family: "Poppins", sans-serif;
}

.form-heading {
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
  color: #ff3c00;
}

.modalBackground {
  width: 100vw;
  height: 100vh;

  position: fixed;

  justify-content: center;
  align-items: center;
}

.modalContainer {
  position: fixed;
  /* Stay in place */
  z-index: 99999999999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 16px;
}

/* Responsive Styles */
@media (min-width: 576px) {
  .modal-content {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .modal-content {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .modal-content {
    max-width: 500px;
  }
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.dropdown {
  background-color: white;
  display: flex;
  width: 400px;
  border: 1px solid #f4ecec;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  border-radius: 22px;
  padding: 10px 39px;
}

.dropdown:empty {
  border: none;
  height: 0px;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 8px 0;
  font-weight: bold;
  position: relative;
}

.dropdown-row::before {
  content: url("../../../../imagesmy/from.svg");
  position: absolute;
  left: -30px;
  top: 12px;
}

.flighttitleCloseBtn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 35px;
}

.flighttitleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.flightPrice {
  display: flex;
  justify-content: space-around;
}

.flightPrice h3 {
  position: relative;
  left: 280px;
  top: 50px;
  color: white;
}

.flightPrice p3 {
  position: relative;
  left: 150px;
  top: 20px;
  font-weight: bold;
  color: white;
}

.flightname {
  display: flex;
}

.flightname hr {
  width: 200px;
  position: relative;

  top: 50px;
  border-top: 1px dashed red;
}

.flightname p {
  position: relative;
  top: 60px;
  left: 20px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.flightname p1 {
  position: relative;
  top: 60px;
  right: 20px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.timePrice {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 50px;
}

.timePrice p2 {
  position: relative;
  bottom: 20px;
  left: 200px;
  font-weight: bold;
  color: white;
}

.timePrice Button {
  position: relative;
  left: 200px;
  top: 10px;
  border: solid orange;
  background-color: #ff3c00;
  font-weight: bold;
  color: white;
}

@media only screen and (max-width: 760px) {
  .modalBackground {
    position: relative;
    bottom: 300px;
    right: 360px;
  }

  .dropdown {
    width: 210px;
  }

  .makeStyles-pageContent-27 {
    margin: 0 !important;
  }
}

.grey-field {
  background-color: #f0f0f0;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  padding: 9px 29px;
  height: 78px;
}

.grey-field span {
  color: #8e8e8e;
  font-weight: 600;
  margin-left: 10px;
}

.css-luel6v-MuiInputBase-root {
  color: #000000 !important;
  font-weight: 700 !important;
  background-color: unset !important;
}

.botharrow {
  position: relative;
}

.botharrow::after {
  content: url("../../../../imagesmy/bothsidearrow.svg");
  position: absolute;
  right: -25px;
  z-index: 999;
  top: 16px;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 12px;
}

.rounded-right {
  border-radius: 12px 0px 0px 12px !important;
}

.rounded-left {
  border-radius: 0px 12px 12px 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.MuiPaper-elevation1 {
  border-radius: 22px !important;
  background: #fff !important;
  box-shadow: 0px 0px 37.87297px 0px rgba(0, 0, 0, 0.17) !important;
}

.MuiSelect-select:focus {
  background-color: unset !important;
}

.select-person {
  padding: 0 !important;
}

.MuiSelect-select {
  padding: -6px 0px 0px !important;
}

.rounded-btn {
  background: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

/* .form-wrapper{
    box-shadow: 0px 0px 37.87297px 0px rgba(0, 0, 0, 0.17);
  } */

.MuiInput-underline:after {
  border-bottom: unset !important;
}

.grey-fieldtravel {
  background-color: #f0f0f0 !important;
  border-radius: 12px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 9px 13px !important;
  height: 78px !important;
}

.grey-fieldtravel span {
  color: #8e8e8e;
  font-weight: 600;
}

.adut-increment {
  border-radius: 45px;
  background: #e9e9e9;
  padding: 4px 8px !important;
  /* margin-left: 60px !important; */
}

.flex {
  display: flex !important;
  align-items: center !important;
}

.incre-decre {
  min-width: 24px !important;
  height: 24px !important;
  background-color: white !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: black !important;
}

.justify-between {
  justify-content: space-between !important;
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.adults-no {
  padding: 0 10px !important;
}

.done-btn {
  border-radius: 8px !important;
  background: #4052f3 !important;
  color: white !important;
  padding: 4 12px !important;
  width: 100% !important;
}

.select-font {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.loader-wrapper {
  max-width: 481px;
  margin: auto;
  height: 468px;
  border-radius: 40px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.loader-wrapper p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.loader-wrapper h2 {
  margin: 0px;
  color: #000;
}

.contact-btn {
  background-color: #4052f3;
  border-radius: 8px;
  color: white;
}
