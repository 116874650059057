/* Main Container */
.navaddf {
  display: flex;
  width: 100%;
}

.add-f-Container {
  flex: 4;
  padding: 40px;
  background-color: #f7f9fc;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

/* Form Container */
.addf-form-Container {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.addf-form-Container p {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.addf-form-Container label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-top: 15px;
}

.addf-form-Container input,
.addf-form-Container select {
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 5px 20px;
  font-size: 14px;
  color: #555;
  transition: border-color 0.3s ease;
}

.addf-form-Container input:focus,
.addf-form-Container select:focus {
  border-color: #007acc;
  outline: none;
}

.addf-form-Container select {
  background-color: #f9f9f9;
  appearance: none;
}

/* Button Styling */
.add-flight-btn {
  display: block;
  width: 150px;
  margin: 20px auto 0;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-flight-btn:hover {
  background-color: #005a99;
  transform: scale(1.05);
}

/* Label Styling */
.addf-form-Container label {
  margin-left: 20px;
  font-size: 14px;
}

/* Input and Select Specific Styling */
.s-origin,
.s-destination,
.s-airline,
.s-airline-c,
.i-adultfare,
.i-childfare,
.i-infantfare,
.i-flightduration,
.i-flightstop,
.i-airline-logo {
  width: calc(100% - 40px);
  margin: 10px 20px;
}

.i-adultfare-label,
.i-childfare-label,
.i-infantfare-label,
.i-flightduration-label,
.i-flightstop-label,
.airline-logo-lable {
  display: inline-block;
  width: 100%;
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  color: #555;
}

.i-flightstop {
  width: calc(100% - 40px);
}

/* Image Upload Section */
.img-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-airline-logo {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: 20px;
}

/* Time Inputs */
.i-flightstop,
.i-flightstop[type="time"] {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 10px 20px;
  width: calc(100% - 40px);
  transition: border-color 0.3s ease;
}

.i-flightstop:focus {
  border-color: #007acc;
}

/* General Hover Effects */
.addf-form-Container input:hover,
.addf-form-Container select:hover {
  border-color: #007acc;
}

/* Headings */
.add-f-Container h1 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}
.addf-form-Container hr {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .addf-form-Container input,
  .addf-form-Container select {
    width: calc(100% - 20px);
    margin: 5px 10px;
  }

  .add-flight-btn {
    width: 100%;
    margin: 15px 0;
  }
}
