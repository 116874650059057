/* Topbar Styling */
.topbar {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  transition: background-color 0.3s ease;
}

.topbarWrapper {
  height: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 28px;
  color: #007acc;
  cursor: pointer;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #005a99;
}

.topbariconContainer button {
  height: 35px;
  width: 100px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.topbariconContainer button:hover {
  background-color: #005a99;
  color: #ffffff;
  cursor: pointer;
}
