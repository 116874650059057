.navA {
  display: flex;
  width: 100%;
}
.aContainer {
  flex: 4;
}

.airline-Container {
  margin: 100px;
}
.airline-Container input {
  width: 300px;
  height: 40px;
  border-radius: 6px;
  margin-left: 20px;
}
.airline-Container h2 {
  text-align: center;
  margin-bottom: 50px;
}
.airline-Container button {
  height: 40px;
  width: 100px;
  font-size: 15px;
  color: rgb(92, 79, 211);
  font-weight: 600;
  border: none;
  border-radius: 6px;
  position: relative;
  left: 20px;
  top: 5px;
}
.disable {
  background-color: black;
}

.img-selector {
  margin-left: 70px;
  size: 20px;
}

.img-Container {
  height: 60px;
  width: 100px;
}
.cellImg {
  height: 60px;
  width: auto;
  margin-bottom: 5px;
}
