*{
    font-family: 'Poppins', sans-serif;
}
.Top-Container
{
    background-image: url('/images/flights/top-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
}
.Top-Container h1{
    color: #fff;
    font-size: 80px;
   position: relative;
   top: 60px;
    
  }

/* .flight-main-container
{
 
   
} */
 

.flight-form-iner{
    margin: 100px;
    padding: 50px;
    border: 2px solid #ff3c00;

}
.main-card-container{
    margin: 30px;
}
.card-main-heading{ 
    font-size: 25px;
    color: #ff3c00;
    text-align: center;
}
.card-sub-heading{ 
    font-size: 20px;
   text-align: center;
}
.card-container{
    display: flex;
   margin-top: 30px;
  justify-content: space-between;
  
}
.f-card-1{
    height: 300px;
    width: 370px;
    background: url('/images/flights/cards/abuja.jpeg') center center/cover no-repeat;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-2{
    height: 300px;
    width: 370px;
    background: url('/images/flights/cards/accra.jpeg') center center/cover no-repeat;
    border: 2px solid #ff3c00;
    border-radius: 10px;
    
    background-position: top 40px right 0px;
}

.f-card-3{
    background: url('/images/flights/cards/colombo.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-4{
    background: url('/images/flights/cards/entebbe.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-5{
    background: url('/images/flights/cards/freetown.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-6{
    background: url('/images/flights/cards/kathmandu.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.card-topbar{
    height: 50px;
    width: 100%;
    background: url('/images/card-top.png') center center/cover no-repeat;
}
.card-topbar h4 {
    
   position: relative;
   top: 5px;
   left: 10px;
   
   color: #fff;
}
.card-topbar p {
    margin-left: 25px;
    color: #fff;
    
 }
 .card-topbar h3 {
    font-size: 25px;
    position: relative;
    color: #fff;
    left: 120px;
    bottom:20px ;
 }
 .card-topbar img{
    height: 40px;
    width: 100px;
    position: relative;
    left: 240px;
    bottom: 60px;
 }
 .cards-button{
    background-color: rgb(112, 110, 110);
    height: 40px;
    width: 130px;
    font-size: 15px;
    font-weight: 700;
    position: relative;
    left: 140px;
    top: 180px;
    border: 1.5px solid #ff3c00;
    border-radius: 5px;
 }
 .cards-button:hover{
    
    background-color:#ff3c00 ;

    color: #fff;
    border: 1.5px solid rgb(112, 110, 110);
   
 }
 .cards-button a{
    text-decoration: none;
    

    color: #fff;
    
   
 }

 .below-cards{
   margin: 50px;
 }
 .below-cards h3{
    margin: 20px;
    text-align: center;
    font-size: 25px;
    
 }
.card2-container {
    margin: 90px;
    display: flex;
    justify-content: space-between;
}
.card2-1{
    background-color: #ff3c00;
    width: 300px;
    height: 300px;
    border-radius: 10px;
}
.card2-1 h4{
   
    font-size: 20px;
    position: relative;
    top: 10px;
    left: 45px;
    color: white;
}
.card2-1 p{
   
    margin: 20px;
    color: white;
}
.end-container{
    margin: 50px;
}
.end-container h3{
   text-align: center;
   font-size: 25px;
}
.end-container p{
    margin: 30px;

}


 @media screen and (max-width: 768px) {

    .Top-Container
{
   
    width: 100%;
    
    
}

    .Top-Container h1{
       
        font-size: 40px;
      
        
      }
  
    .flight-form-iner{
        margin: 20px;
        
    
    }
    .card-container{
        flex-direction: column;
        align-items: center;

      
    }
    .f-card-1{
        margin: 20px;
    }
    .f-card-2{
        margin: 20px;
    }
    .f-card-3{
        margin: 20px;
    }
    .f-card-4{
        margin: 20px;
    }
    .f-card-5{
        margin: 20px;
    }
    .f-card-6{
        margin: 20px;
    }

    .card2-container {
        
       flex-direction: column;
       align-items: center;
        
    }
    .card2-1{
      margin-top: 30px;
     
    }
    
}