/* 

  
.from-container{
  position: relative;
  right: 120px;
}

.Travel-To{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 125px;
  top: 40px;
}
.To-container{
  position: relative;
  right: 50px;
}
.Flight-Class{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 60px;
  
}

.Flight-Class p{
  
  position: relative;
  left: 30px;
  
  
}
.class-name-container{
  position: relative;
  right: 200px;
}
.Adult-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 80px;
}
.Adult-Fare p1{
 position: relative;
  right: 210px;
 
}
.Child-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 100px;
}
.Child-Fare p1{
  
  position: relative;
  right: 210px;
  
}
.Infant-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 120px;
}
.Infant-Fare p1{
 position: relative;
  right: 210px;
  
}
.Total-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 140px;
}
.Total-Fare p1{
  
  position: relative;
  right: 200px;
  font-size: 20px;
  color: #ff3c00;
}

 .hr-Container hr{
  border: .5px solid #ff3c00 ;
 }

 .Input-Container{
  text-align: center;
 }
 .Input-Container h3{
  font-size: 30px;
  color: #ff3c00;
 }
 .sub-Heading-Container{
  margin-top: 20px;

 }
 .sub-Heading-Container p{
  font-size: 15px;
  
 }

 .F-name-Textfield-Container{
  display: flex;
 }



 .F-name-Textfield{
  width: 500px;
  position: relative;
  left: 210px;
  
 }

 
 .L-name-Textfield{
  width: 500px;
  position: relative;
  left: 305px;
  
 }

 .phone-Number-Textfield{
  width: 500px;
  position: relative;
  left: 305px;
 }
 .email-above-p{
  margin-top: 20px;
  font-size: 12px;
 }
 .email-above-hr hr{
  border: 0.5px solid #ff3c00 ;
 }
 .After-hr-Container p1{
         font-size: 12px;
 }
 .email-container{
  width: 500px;
  position: relative;
  left: 305px;
 }
 .Checkbox-Container{
  display: flex;
 }
 .Checkbox-Container p{
  font-size: 12px;
  margin-top: 12px;
 }
 .last-btn button{
  background-color: #ff3c00;
  width: 200px;
  text-align: center;
position:relative ;
right: 30px;
border-radius: 6px;
height: 30px;
border: none;
font-weight: bold;
color: #fff;


 }
.modal-Container hr{
  border: 0.5px solid #dadada ;
} */

.d-flex {
  display: flex;
}

.flight-book-bg {
  border-radius: 15px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
}

.bookFlight-Main {
  width: 100%;
  background-color: whitesmoke;
}

.Card-Main-Container {
  background-color: white;
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */

  gap: 10px;
}
.grid-container-flight {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
}
.grid-container-flight-center {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
}

.column-3 {
  grid-column: span 3; /* Set the width of this column to 3 columns */
}
.column-4 {
  grid-column: span 4 !important; /* Set the width of this column to 3 columns */
}
.column-5 {
  grid-column: span 5 !important; /* Set the width of this column to 3 columns */
}
.column-2 {
  grid-column: span 2; /* Set the width of this column to 3 columns */
}
.column-7 {
  grid-column: span 7; /* Set the width of this column to 3 columns */
}
.column-6 {
  grid-column: span 6 !important; /* Set the width of the second column to 6 columns */
}
.column-8 {
  grid-column: span 8; /* Set the width of this column to 3 columns */
}
.column-9 {
  grid-column: span 9; /* Set the width of this column to 9 columns */
}
.column-12 {
  grid-column: span 12 !important;
}
.Ocolor {
  color: #fc3c00;
}

.p-30 {
  padding: 30px;
}

.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fs-30 {
  font-size: 30px;
}
.pText {
  color: #727272;
}

.align-items-center {
  align-items: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ms-10 {
  margin-left: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.p-12 {
  padding: 12px;
}
.p-10 {
  padding: 10px 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-40 {
  padding: 40px !important;
}
.ps-10 {
  padding-left: 10px !important;
}
.p-20 {
  padding: 20px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-30 {
  margin-top: 30px !important ;
}
.my-20 {
  margin: 20px 0 !important;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mt-100 {
  margin-top: 100px !important;
}
.justify-content-between {
  justify-content: space-between;
}

.form-bg {
  border-radius: 15px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
}
.text-center {
  text-align: center !important;
}
.text-black {
  color: black !important;
}
.text-white {
  color: #fff !important;
}
.fs-18 {
  font-size: 18px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-20 {
  font-size: 20px;
}
.fs-28 {
  font-size: 28px !important;
}
.myInputs {
  border-radius: 8px !important;
  border: 1px solid #d1d1d1 !important;
  background: #f0f0f0 !important;
}
.subBtn {
  border-radius: 8px !important;
  background-color: var(--2ndry, #4052f3) !important;
  color: white !important;
  border: none !important;
}
.orangeBtn {
  border-radius: 8px !important;
  background-color: #fc3c00 !important;
}

.flight-card-bg {
  border-radius: 15px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
}
.flight-Cards-Container {
  overflow-y: hidden !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.mb-12 {
  margin-bottom: 12px !important;
}

.flight-logo {
  width: 100% !important;
  height: auto !important;
  padding: 11px 43px;
}
.btnGreen {
  border-radius: 6px;
  background: #31aa3d;
  border: none !important;
  padding: 8px 15px;
}
.py-27 {
  padding: 27px 0 !important;
}
.py-10 {
  padding: 10px 10px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important ;
}
.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px !important;
}
.ml-10 {
  margin-left: 10px;
}
.no-wrap {
  text-wrap: nowrap;
}
.border-end {
  border-right: 1px solid #dadada !important;
}
.px-15-10 {
  padding: 10px 15px !important;
}

.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}

.btnCall {
  border-radius: 9px;
  background: rgba(64, 82, 243, 0.21);
  border: unset !important;
}
.pb-32 {
  padding-bottom: 32px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.pl-23 {
  padding-left: 23px !important;
}
.justify-content-center {
  justify-content: center;
}

.adultBg {
  border-radius: 9px;
  background: #efefef;
}
.w-100 {
  width: 100% !important;
}
.h-50 {
  height: 50px !important;
}
.p-20 {
  padding: 20px !important;
}
.css-ypiqx9-MuiDialogContent-root {
  padding: 0px !important;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.Card-Left-Container {
  text-align: center;
  grid-column: span 3;
  padding: 27px 0;
  border-right: 1px solid #dadada !important;
}
.Card-Center-Container {
  padding: 29px 6px !important;
  grid-column: span 6;
  border-right: 1px solid #dadada !important;
}
.Card-Right-Container {
  text-align: center;
  grid-column: span 3;
  padding-bottom: 32px !important;
  padding: 15px !important;
}
.dotted-line {
  width: 200px;
}
.ml-8 {
  margin-left: 8px !important;
}
.mr-73 {
  margin-right: 73px;
}
.mr-22 {
  margin-right: 22px;
}
.ml-26 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px !important;
}

.flight-tickets {
  grid-column: span 3;
}
.flight-detail {
  grid-column: span 9;
  padding: 0 !important;
}
.From-Stop-To-Caontainer {
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Center-Departure {
  text-align: center;
  grid-column: span 3;
}
.airplane-line {
  grid-column: span 6;
}
.Center-Travel-To {
  grid-column: span 3;
  text-align: center;
}

.best {
  grid-column: span 4;
  border-right: 1px solid #dadada !important;
}
.bestBtn {
  border-radius: 10px;
  width: 86%;
}
.cheapestBtn:active {
  border-radius: 10px;
}
.cheapest {
  grid-column: span 4;
  border-right: 1px solid #dadada !important;
}
.quickest {
  grid-column: span 4;
}
.MuiCheckbox-root {
  color: #fc3c00 !important;
  border-color: none !important;
}
@media screen and (max-width: 1200px) {
  .dotted-line {
    width: 340px;
  }

  .grid-container-flight {
    flex-direction: row;
  }
  .mr-68 {
    margin-right: 65px;
  }
  .ml-26 {
    margin-left: 26px;
  }

  .Card-Left-Container {
    text-align: center;
    order: 2;
    grid-column: span 6;

    padding: 27px 0;

    border-right: 1px solid #dadada !important;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .Card-Right-Container {
    text-align: center;
    order: 3;
    grid-column: span 6;
    padding-bottom: 32px !important;
    padding: 15px !important;
  }
  .Card-Center-Container {
    padding: 20px !important;
    order: 1;
    border-right: unset !important;
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
  }
}

@media screen and (max-width: 991px) {
  .firstNamecol {
    grid-column: span 6 !important;
  }
  /* .progresscol{
    grid-column:span 6 !important;
  } */

  .seat-icons {
    margin-top: 90px !important;
  }
  .best-option {
    grid-column: span 6 !important;
  }
  .your-booking {
    grid-column: span 12 !important;
  }
  .grid-container {
    flex-direction: row;

    gap: 10px;
  }
  .flight-tickets {
    grid-column: span 12;
    order: 2;
  }
  .flight-detail {
    grid-column: span 12;
    padding: 0 !important;
  }
  .column-6 {
    grid-column: span 12 !important;
  }
  .column-9 {
    grid-column: span 12 !important;
  }
  .seat-icons {
    position: relative !important;
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .progressBar {
    width: 150px !important;
    font-size: 14px !important;
  }
  .firstNamecol {
    grid-column: span 12 !important;
  }
  .confirmseat {
    display: block !important;
  }
  .MuiGrid-grid-xs-1 {
    flex-grow: 0 !important;
    max-width: 12.333333% !important;
    flex-basis: 12.333333% !important;
  }
  .seat-icons {
    margin-top: 70px !important;
  }
  .best-option {
    grid-column: span 12 !important;
  }
  .sikness {
    grid-column: span 12 !important;
  }
  .bestBtn {
    border-radius: 10px;
    width: 100%;
  }
  .grid-container-flight-center {
    flex-direction: row;
  }

  .Center-Departure {
    text-align: center;
    grid-column: span 12;
  }
  .airplane-line {
    grid-column: span 12;
  }

  .Center-Travel-To {
    grid-column: span 12;
    text-align: center;
  }

  .dotted-line {
    width: 280px;
  }

  .grid-container-flight {
    flex-direction: row;
  }
  .mr-68 {
    margin-right: 65px;
  }
  .ml-26 {
    margin-left: 26px;
  }

  .Card-Left-Container {
    text-align: center;
    order: 1;
    grid-column: span 12;

    padding: 27px 0;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .Card-Right-Container {
    text-align: center;
    order: 3;
    grid-column: span 12;
    padding-bottom: 32px !important;
    padding: 15px !important;
  }
  .Card-Center-Container {
    padding: 10px !important;
    order: 2;
    border-right: unset !important;
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
  }
}
.ps-20 {
  padding-left: 24px !important;
}

@media screen and (max-width: 580px) {
  .progressBar {
    /* width: 100px !important; */
    font-size: 12px !important;
    text-wrap: nowrap;
  }
  .best {
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .cheapest {
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .quickest {
    grid-column: span 12;
  }
}

.modal {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  z-index: 2;
}

/* checkout css  */
.css-eglki6-MuiLinearProgress-root {
  background-color: #d9d9d9 !important ;
}
.pBar {
  height: 10px !important;
  border-radius: 10px !important;
}
.your-booking-bg {
  border-radius: 12px;
  background: #eef0ff;
}

.dotted-border {
  border-bottom: 2px dotted #cacfff;
}
.greenColor {
  color: #458f2a;
}

.our-guarantees {
  border-radius: 15px !important;
  border: 1px solid #dadada !important;
  background: #fff !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07) !important;
}

.border-bottom {
  border-bottom: 1px solid #dadada;
}

.bordernone {
  border-bottom: none !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.addanotherbtn {
  border-radius: 76px !important;
  background: #fc3c00 !important;
}
.one-bg {
  background-color: #d9d9d9 !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 50% !important;
}

.blue {
  color: #4052f3 !important;
}
.bg-blue {
  background-color: #4052f3 !important;
}
.bg-cross {
  /* background-color: #4052F3 !important; */
  background-image: url(../../../imagesmy/cross.png) !important;
  background-color: #d9d9d9 !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.light-text {
  color: #b7b7b7 !important;
}
.select-flight-bg {
  border-radius: 7px !important;
  background: #f6f6f6 !important;
}

.hannover-bg {
  border-radius: 7px !important;
  border: 1px solid #f0f0f0 !important;
  background: #fff !important;
}

.seats-checkbox-bg {
  border-radius: 7px 7px 0px 0px !important;
  background: #f6f6f6 !important;
}
.no-resize {
  resize: none !important;
}

.seat-icons {
  margin-top: 110px !important;
}

.bg-orange {
  background-color: #fc3c00 !important;
  background-image: url(../../../imagesmy/footspace.png) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.emilType input[type="email"] {
  padding: 18.5px 14px !important;

  margin-left: 0px;
  left: 0 !important;
  border-radius: 6px;
  border: none;
  background: #f1f1f1;
  width: 100% !important;
}

.p-phone {
  padding: 18.5px 14px !important;
}

.bestBtn {
  padding: 36px 19px !important;
}

.tag-msg {
  font-size: 12px;
}

.pText {
  font-size: 15px;
  font-weight: 700;
}

.returnimg {
  transform: rotate(180deg) !important;
}
.btnCall {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}
