/* Main Content Container */
.nav {
  display: flex;
  width: 100%;
}

.admin {
  flex: 4;
  padding: 50px;
  background-color: #f7f9fc;
}

/* User Container */
.userContainer {
  display: flex;
  justify-content: flex-end;
  margin: 50px;
}

.userContainer button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-decoration-color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.userContainer button:hover {
  background-color: #005a99;
}

/* Table Styling */
.a-table-Container {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.user-tabel {
  min-width: 650px;
}

.MuiTableCell-root {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 10px 20px;
}

.MuiTableHead-root {
  background-color: #007acc;
  color: #ffffff;
}

.MuiTableHead-root .MuiTableCell-root {
  color: #ffffff;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background-color: #f7f9fc;
}

.MuiTableBody-root .MuiTableRow-root:hover {
  background-color: #eef3f7;
}

/* Image Container */
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  height: 30px;
  width: 60px;
  border-radius: 5px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.img-container img:hover {
  transform: scale(1.1);
}

/* Button Styling */
.edit-btn,
.delete-btn {
  margin: 0 5px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-btn {
  color: #ffffff;
  background-color: #4caf50;
  border: none;
}

.edit-btn:hover {
  background-color: #388e3c;
}

.delete-btn {
  color: #ffffff;
  background-color: #e53935;
  border: none;
}

.delete-btn:hover {
  background-color: #c62828;
}
