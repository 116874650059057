*{
    font-family: 'Poppins', sans-serif;
}
.aboutus{
    background-image: url('/images/Mauritius.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
}
.about-container{
    display: flex;
}


.about-image{

    background: url('/images/aboutplane.jpg') center center/cover no-repeat;
   margin-top: -50px;
   margin-left: 50px;
   height: 400px;
   width: 3000px;
 
   border-radius: 20px;

}

.about-content{

    margin-top: 40px;
   margin-left: 50px;
   margin-right: 80px;


}
.about-content h1{

    font-size: 50px;
    padding-bottom: 30px;
    color: #ff3c00;
    margin-top: -10px;
}
.about-content p1{

    
    margin-bottom: 20px;
    
}



.main-box-container h1{
 
    color: #ff3c00;
   
}

.box-container{

    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 10px;
    
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    font-family: 'Poppins', sans-serif;
}
.box{
    position: relative;
    width: 350px;
    height: 350px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}
.box .icon{
    position: relative;
    width: 70px;
    height: 70px;
    color: #fff;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 1s;
}
.box:nth-child(1) .icon{

    box-shadow: 0 0 0 0 #535353;
    background: #535353;
}
.box:nth-child(1):hover .icon{

    box-shadow: 0 0 0 400px #535353;
    background: #535353;
}
.box:nth-child(2) .icon{

    box-shadow: 0 0 0 0 #ff3c00;
    background: #ff3c00;
}
.box:nth-child(2):hover .icon{

    box-shadow: 0 0 0 400px #ff3c00;
    background: #ff3c00;
}
.box:nth-child(3) .icon{

    box-shadow: 0 0 0 0 #0f0f0f;
    background: #0f0f0f;
}
.box:nth-child(3):hover .icon{

    box-shadow: 0 0 0 400px #0f0f0f;
    background: #0f0f0f;
}
.box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
}
.box:hover .content{
    color: #fff;
}
.box .content h3{
    font-size: 20px;
    margin: 10px;
    padding: 0;
    font-family: 'Poppins', sans-serif;
   
}
.box .content p{
    
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
   
}

.end-part{
    margin: 50px;
}

.end-part p{
   
    margin: 50px;
    font-size: 40px;

}
.end-part p1{
  
  text-align: center;
    font-family: 'Poppins', sans-serif ;
}


@media screen and (max-width: 768px) {
  
    .about-container{
        flex-direction: column;
    }



    .about-image{
        margin-left: 20px;
   height: 200px;
   width: 150px;
       

    }
    .aboutus h1{

        font-size: 50px;
        
    }
    .about-content h1{

        font-size: 25px;
        padding-bottom: 30px;
        color: #ff3c00;
        margin-top: -10px;
    }
    .about-content p1{

    
        font-size: 15px;
       
    }

    .box-container{

       
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .end-part p{
   
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    
    }
}