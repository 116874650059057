*{
  font-family: 'Poppins', sans-serif;
}

  .contact-container{
    width: 100%;
    
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .contact-container h2{
     
    color: #ff3c00;
   font-size: 50px;
   margin: 50px;
   text-align: center;

  }
  .contact-container p{
      margin-bottom: 40px;
    font-family: 'Poppins', sans-serif; ;
  }

  .contact-container .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contact-container .content .left-side{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content .left-side::before{
    content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #ff3c00;
  }
  .content .left-side .details{
    margin: 14px;
    text-align: center;
  }
  .content .left-side .details i{
    font-size: 30px;
    color: #ff3c00;
    margin-bottom: 5px;
  }
  .content .left-side .details .topic{
    font-size: 18px;
    font-weight: 500;
  }
  .content .left-side .details .text-one,
  .content .left-side .details .text-two{
    font-size: 14px;
    color: #575656;
  }
  .contact-container .content .right-side{
    width: 50%;
    margin-left: 90px;
  }
  .email_contact{
   
      color: #575656;
        text-decoration: none;
        font-family: "bahnschrift";
      }
      .email_contact:hover{
        color: #ff3c00;
      }
      .phone_contact{
   
        color: #575656;
          text-decoration: none;
          font-family: "bahnschrift";
        }
        .phone_contact:hover{
          color: #ff3c00;
        }


    
  
  .content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #ff3c00;
  }
  .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
  }
  .right-side .input-box input,
  .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .right-side .message-box{
    min-height: 110px;
  }
  .right-side .input-box textarea{
    padding-top: 6px;
  }
  .right-side .button{
    display: inline-block;
    margin-top: 12px;
  }
  .button {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #636262;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .button:hover{
    background: #ff3c00;
  }
  
  @media (max-width: 950px) {
    .contact-container{
      width: 100%;
      padding: 30px 40px 40px 35px ;
    }
   

    

    .contact-container .content .right-side{
     width: 75%;
     margin-left: 55px;
  }
  }
  @media (max-width: 820px) {
    .container{
      margin: 40px 0;
      height: 100%;
    }
    .contact-container .content{
        flex-direction: column-reverse;
    }
   .container .content .left-side{
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     justify-content: center;
     flex-wrap: wrap;
   }
   .container .content .left-side::before{
     display: none;
   }
   .container .content .right-side{
     width: 100%;
     margin-left: 0;
   }
   .contact-container h2{
     
    font-size: 25px;
    text-align: center;

  }
  .contactus{
    font-size: 50px;
  }
  }
  
  