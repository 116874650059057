* {
    font-family: 'Poppins', sans-serif;
}

.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background: rgb(253, 253, 253);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    /* max-width: 1500px; */
}

.navbar-logo {
    color: rgb(8, 8, 8);
    justify-self: start;
    /* margin-left: 10px; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    /* margin-right: 80px; */
    font-weight: 700;
}

.nav-logo {
    background: url('/images/logo.png') center center/cover no-repeat;
    background-size: 100% 100%;
    height: 42px;
    width: 272px;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    /* width: 50vw; */
    justify-content: end;
    /* margin-right: 4rem; */
    ;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: rgb(15, 15, 15);
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 17px;
    font-weight: 600;
}

.nav-links:hover {
    background-color: #4052F3;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: #fff;
}

.fa-bars {
    color: #4052F3;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.contact-btn {
    background-color: #4052F3;
    border-radius: 8px;
    color: white;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: #4052F3;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        background-color: #4052F3;
        border-radius: 0;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .nav-logo {
        background: url('/images/logo.png') center center/cover no-repeat;
        background-size: 100% 100%;
        height: 50px;
        width: 200px;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times {
        color: #4052F3;
        font-size: 2rem;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4052F3;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    .nav-links-mobile:hover {
        background: #fff;
        color: #4052F3;
        transition: 250ms;
    }
    .navbar-logo {
        color: rgb(8, 8, 8);
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 760px) {
    .navbar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        max-width: 1200px;
    }
    .navbar-logo {
        font-size: 1.5rem;
    }
}

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}