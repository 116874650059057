.logContainer{
   width: 600px;
   height: 800px;
   position: relative;
   left: 300px;
}

.login-container {
  margin: 20px;
  padding: 16px;
  background-color: white;
}
.login-container span{
    position: relative;
    left: 180px;
    color: red;
    
  }
.login-container p{
  margin-left: 50px;
  font-weight: 600;
}
.login-email-label{
  margin-left: 80px;
}
.r-password-label{
  margin-left: 70px;
}
input[type=email], input[type=password] {
  width: 400px;
  padding: 15px;
  margin-left: 20px;
  border-radius: 6px;
  display: inline-block;
  border: none;
  background: #f1f1f1;
  position: relative;
  left: 50px;
}
input[type=email]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
  
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.loginbtn {
  background-color: #1b1f1d;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 20%;
  opacity: 0.9;
  margin-left: 50px;
  position: relative;
  left:300px;
}

.registerbtn:hover {
  opacity: 2;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}
