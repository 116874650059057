@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
}

.home,
.contactus,
.aboutus,
.flights,
.desti {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.dropdown:empty {
    background: none !important;
}

.aboutus {
    background-image: url('/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    width: 100%;
}

.aboutus>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: 10px;
}

.contactus {
    background-image: url('/images/img-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 50vh;
    width: 100%;
    font-size: 100px;
}

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}

.MuiList-padding {
    padding: 7px 10px !important;
}


.seat-gap {
    margin: 10px 5px;
}

.grid-container {
    margin-bottom: 15px;
}

.w-seats {
  height: 23px;
  width: 23px;
}


.makeStyles-pageContent-1 {
    margin: -65px 0px 0px 0px !important ;
    padding: 24px;
  }


  .tag-wraaper{
    gap: 15px;
  }
  .text-center{
    text-align: center !important;
  }