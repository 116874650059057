.py-60{
    padding: 60px 0px;
}

.text-center{
    text-align: center;
}

.grey-card-wrapper{
    background-color:#EEE ;
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    padding: 20px;
}


.grey-card-wrapper h4{
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0px;
}
.grey-card-wrapper p{
    font-weight: 500;
    line-height: 35px;
}
h2{
    margin-bottom: 20px ;
}
.d-none{
    display: none;
  }

@media screen and (min-width: 992px) {
    .d-lg-block {
      display: block;
    }
  }


 