.py-60 {
  padding: 60px 0px;
}

.slider-wrapper {
  background-image: url(../../../../imagesmy/greybg.png);
  background-color: #f3f3f3;
}

.slider-card {
  text-align: center;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
}

.slider-card .book-btn {
  margin: 54px 0px;
  border-radius: 7.973px;
  background: #000;
  font-size: 13px 29px;
  font-weight: 700;
  padding: 10px 20px;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slider-card img {
  margin-left: auto;
  margin-right: auto;
}

.slider-card {
  height: 400px;
}

.slick-next:before {
  content: url("../../../../imagesmy/next.svg");
}

.slick-prev:before {
  content: url("../../../../imagesmy/prev.svg");
}

.slick-prev {
  left: -76px !important;
}

.slick-prev {
  left: -76px !important;
}

.slick-next {
  right: -43px;
}

.slider-heading {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.section-heading {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 40px;
}

/* Hide navigation arrows for screens with a max-width of 768px */

@media (max-width: 1200px) {
  .slick-arrow {
    display: none !important;
  }
  .section-heading {
    font-size: 18px;
  }
}
