@import url(https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
}

.home,
.contactus,
.aboutus,
.flights,
.desti {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.dropdown:empty {
    background: none !important;
}

.aboutus {
    background-image: url('/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    width: 100%;
}

.aboutus>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: 10px;
}

.contactus {
    background-image: url('/images/img-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    height: 50vh;
    width: 100%;
    font-size: 100px;
}

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}

.MuiList-padding {
    padding: 7px 10px !important;
}


.seat-gap {
    margin: 10px 5px;
}

.grid-container {
    margin-bottom: 15px;
}

.w-seats {
  height: 23px;
  width: 23px;
}


.makeStyles-pageContent-1 {
    margin: -65px 0px 0px 0px !important ;
    padding: 24px;
  }


  .tag-wraaper{
    grid-gap: 15px;
    gap: 15px;
  }
  .text-center{
    text-align: center !important;
  }
*{
    font-family: 'Poppins', sans-serif;
}
.aboutus{
    background-image: url('/images/Mauritius.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
}
.about-container{
    display: flex;
}


.about-image{

    background: url('/images/aboutplane.jpg') center center/cover no-repeat;
   margin-top: -50px;
   margin-left: 50px;
   height: 400px;
   width: 3000px;
 
   border-radius: 20px;

}

.about-content{

    margin-top: 40px;
   margin-left: 50px;
   margin-right: 80px;


}
.about-content h1{

    font-size: 50px;
    padding-bottom: 30px;
    color: #ff3c00;
    margin-top: -10px;
}
.about-content p1{

    
    margin-bottom: 20px;
    
}



.main-box-container h1{
 
    color: #ff3c00;
   
}

.box-container{

    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 10px;
    
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    font-family: 'Poppins', sans-serif;
}
.box{
    position: relative;
    width: 350px;
    height: 350px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}
.box .icon{
    position: relative;
    width: 70px;
    height: 70px;
    color: #fff;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 1s;
}
.box:nth-child(1) .icon{

    box-shadow: 0 0 0 0 #535353;
    background: #535353;
}
.box:nth-child(1):hover .icon{

    box-shadow: 0 0 0 400px #535353;
    background: #535353;
}
.box:nth-child(2) .icon{

    box-shadow: 0 0 0 0 #ff3c00;
    background: #ff3c00;
}
.box:nth-child(2):hover .icon{

    box-shadow: 0 0 0 400px #ff3c00;
    background: #ff3c00;
}
.box:nth-child(3) .icon{

    box-shadow: 0 0 0 0 #0f0f0f;
    background: #0f0f0f;
}
.box:nth-child(3):hover .icon{

    box-shadow: 0 0 0 400px #0f0f0f;
    background: #0f0f0f;
}
.box .content{
    position: relative;
    z-index: 1;
    transition: 0.5s;
}
.box:hover .content{
    color: #fff;
}
.box .content h3{
    font-size: 20px;
    margin: 10px;
    padding: 0;
    font-family: 'Poppins', sans-serif;
   
}
.box .content p{
    
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
   
}

.end-part{
    margin: 50px;
}

.end-part p{
   
    margin: 50px;
    font-size: 40px;

}
.end-part p1{
  
  text-align: center;
    font-family: 'Poppins', sans-serif ;
}


@media screen and (max-width: 768px) {
  
    .about-container{
        flex-direction: column;
    }



    .about-image{
        margin-left: 20px;
   height: 200px;
   width: 150px;
       

    }
    .aboutus h1{

        font-size: 50px;
        
    }
    .about-content h1{

        font-size: 25px;
        padding-bottom: 30px;
        color: #ff3c00;
        margin-top: -10px;
    }
    .about-content p1{

    
        font-size: 15px;
       
    }

    .box-container{

       
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .end-part p{
   
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    
    }
}
* {
    font-family: 'Poppins', sans-serif;
}

.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background: rgb(253, 253, 253);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    /* max-width: 1500px; */
}

.navbar-logo {
    color: rgb(8, 8, 8);
    justify-self: start;
    /* margin-left: 10px; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    /* margin-right: 80px; */
    font-weight: 700;
}

.nav-logo {
    background: url('/images/logo.png') center center/cover no-repeat;
    background-size: 100% 100%;
    height: 42px;
    width: 272px;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    /* width: 50vw; */
    justify-content: end;
    /* margin-right: 4rem; */
    ;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: rgb(15, 15, 15);
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 17px;
    font-weight: 600;
}

.nav-links:hover {
    background-color: #4052F3;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: #fff;
}

.fa-bars {
    color: #4052F3;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.contact-btn {
    background-color: #4052F3;
    border-radius: 8px;
    color: white;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: #4052F3;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        background-color: #4052F3;
        border-radius: 0;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .nav-logo {
        background: url('/images/logo.png') center center/cover no-repeat;
        background-size: 100% 100%;
        height: 50px;
        width: 200px;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times {
        color: #4052F3;
        font-size: 2rem;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4052F3;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    .nav-links-mobile:hover {
        background: #fff;
        color: #4052F3;
        transition: 250ms;
    }
    .navbar-logo {
        color: rgb(8, 8, 8);
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 760px) {
    .navbar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        max-width: 1200px;
    }
    .navbar-logo {
        font-size: 1.5rem;
    }
}

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}
.bottom-bar-top {
	height: 100px;
	width: 100%;

	display: flex;


}

.bottom-left {
	background-color: #525357;
	width: 50%;
	display: flex;
	align-items: center;
}

.bottom-left h2 {
	color: #fff;


}

.bottom-left p {
	color: #fff;


}

.bottom-left .img img {

	size: 10px;
	width: 50px;
	height: 50px;
	margin-left: 50px;
	margin-right: 30px;
}






.bottom-right {
	background-color: #4e5a79;
	width: 50%;
	display: flex;
	align-items: center;
}

.bottom-bar img {
	height: 100px;
	width: 100%;



}

.bottom-right .img img {

	size: 10px;
	width: 50px;
	height: 50px;
	margin-left: 20px;
	margin-right: 30px;
}

.bottom-right h2 {
	color: #fff;
	font-size: 20px;

}

.bottom-right p {
	color: #fff;
	font-size: 15px;

}

body {

	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}

* {

	margin: 0;
	padding: 0;
	box-sizing: border-box;

	font-family: 'Poppins', sans-serif;

}

.container2 {

	max-width: 1170px;
	margin: auto;

}

.row {

	margin: auto;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;


}

ul {
	list-style: none;
}

.footer {
	background: url(/static/media/footerbg.fea5def1.png) center center/cover no-repeat;
	background-color: #DBDEFF;
	border-top-left-radius: 125;
	font-size: 13px;

}

.footer .container2 {
	padding: 60px 0;
}

.privacy_link {
	text-decoration: none;
	color: #000;

}

.footer-col {
	flex-basis: 25%;
	padding: 10px;
}

.footer-col h4 {
	font-size: 20px;
	color: #000000;
	font-weight: 700;
	text-transform: capitalize;
	margin-bottom: 35px;
	position: relative;
}

/* .footer-col h4::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: -10px;
	background-color: #ff3c00;
	height: 2px;
	box-sizing: border-box;
	width: 70px;
} */

.footer-col ul li:not(:last-child) {
	margin-bottom: 10px;
}

.footer-col ul li a {
	font-size: 16px;
	text-transform: capitalize;
	color: #000000;
	font-weight: 700 !important;
	text-decoration: none;
	display: block;
	transition: all 0.3s ease;
}

.footer-col .card-icons {

	margin-top: 20px;
}


.footer-col ul li a:hover {
	color: #fffafa;
	padding-left: 8px;
}

.footer-col .social-links a {
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: unset !important;
	margin: 0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
	color: #24262b;
	background-color: #ffffff;
}

.footer-col p {
	color: #000000;
	font-weight: 700;
	font-family: "arial";
}

.footer-col h3 {
	color: #a3a3a3;
	font-family: "Swis721 BT";
}

.f-logo {
	height: auto;
	width: auto;
	position: relative;
	bottom: 15px;
	font-size: 30px;
	color: #ff3c00;

}

.f-email {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;

	margin: 10px;


}

.f-email a {

	color: #000;
	text-decoration: none;
	font-family: "bahnschrift";

}	

.f-email a:hover {
	color: #ff3c00;
}

.phone1 a {
	color: #000;
	text-decoration: none;
	font-family: "bahnschrift";
}

.phone1 a:hover {
	color: #ff3c00;
}




hr {
	width: 90%;
	border: 0;
	border-bottom: 1px solid #ff3c00;
	margin: 0px auto;
	margin-bottom: 0px !important;
}

.copyright {
	text-align: center;
	color: #000;
	font-size: 18px;
}

/*responsive*/
@media(max-width: 767px) {
	.footer-col {
		width: 50%;
		margin-bottom: 30px;
	}

	.bottom-bar {
		height: 60px;
		width: 100%;
	}

	.bottom-bar img {
		height: 60px;
		width: 100%;
	}

	/* this section for footer topbar */
	.bottom-bar-top {
		flex-direction: column;
		margin-bottom: 10px;
	}

	.bottom-left {
		width: 100%;
		margin-bottom: 2px;
	}

	.bottom-left h2 {
		color: #fff;
		font-size: 18px;

	}

	.bottom-left p {
		color: #fff;
		font-size: 15px;

	}

	.img {
		height: 50px;
	}

	.bottom-right {
		width: 100%;


	}

	.bottom-right h2 {
		color: #fff;
		font-size: 18px;

	}

	.bottom-right p {
		color: #fff;
		font-size: 15px;

	}



}

@media(max-width: 574px) {
	.footer-col {
		width: 100%;
	}

	.row {
		flex-direction: column;
		align-items: center;
	}

	.footer-col {

		align-items: center;
		text-align: center;
	}

	.footer-col h4::before {
		content: '';
		position: absolute;
		left: 42%;
		bottom: -10px;
		background-color: #ff3c00;
		height: 2px;
		box-sizing: border-box;
		width: 70px;
	}

	.f-email {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		border-bottom: 1px solid #ccc;
		margin-left: 35%;
		align-content: center;
	}


}
.icon-flex a{
	color: #000;
	text-decoration: none;
	font-size: 18px;
	margin-top: 10px;
	font-weight: 700;

}
.icon-flex{
	display: flex;
	align-items: center;
}

.myicon{
	margin-right: 10px;
	margin-top: 10px;
}
.copyright-wrapper{
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 10px 0px;
	flex-wrap: wrap;
}
.topnav {
    background: #FC3C00 !important;
    flex: unset !important;
    padding: 4px 0px;
}

.top-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-nav-left-container {
    display: flex;
    align-items: center;
}


/* .econtainer {
    max-width: 1500px;
} */

.main-container {
    display: flex;
    /* height: 50px; */
    padding-top: 8px;
    justify-content: space-between;
    color: rgb(229, 226, 226);
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.topnav-left-wrapper {
    display: flex;
    align-items: center;
}


/* .pcontainer{
   
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    border-left: 30px solide green;
    
    
  } */

.phone {
    margin-right: 230px;
    display: flex;
    color: rgb(255, 255, 255);
    font-size: 15px;
    align-items: center;
}

.phoneicon {
    margin-right: 5px;
    margin-top: 2;
}

.phone a {
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
    font-family: "bahnschrift";
}

.phone a:hover {
    color: #ff3c00;
}


/* .econtainer{
  
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    
  } */

.email {
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    color: rgb(255, 255, 255);
    font-size: 15px;
}

.email a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 15px;
}

.email a:hover {
    color: rgb(0, 0, 0);
}

.fb {
    size: 20px;
    height: 30px;
    width: 20px;
    margin-right: 20px;
    color: green;
    margin-left: 10px;
}

.insta {
    size: 20px;
    height: 30px;
    width: 30px;
    margin-right: 20px;
}

.wts {
    size: 20px;
    height: 30px;
    width: 30px;
    margin-right: 50px;
}

.our_social {
    display: flex;
    margin-left: 10px;
}

.social-icons-top {
    display: flex;
    font-size: 20px;
    margin-left: 40px;
}

.social-links a {
    display: inline-block;
    height: 37px;
    width: 37px;
    background-color: #ff3c00;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
    margin-left: 7px;
}

.social-links a:hover {
    color: #24262b;
    background-color: #ffffff;
}


/* .acontainer{
   
    background-color: rgb(194, 194, 194);
    width: 25%;
    margin-bottom: 5px;
    
    
  }
  .scontainer{
   
    background-color: rgb(2, 2, 2);
    width: 25%;
    margin-bottom: 5px;
    
    
  } */

.custom-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .custom-container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 1170px;
    }
}

@media screen and (max-width: 960px) {
    .topnav {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .topnav {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .topnav {
        display: none;
    }
}
* {
  font-family: "Poppins", sans-serif;
}

.form-heading {
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
  color: #ff3c00;
}

.modalBackground {
  width: 100vw;
  height: 100vh;

  position: fixed;

  justify-content: center;
  align-items: center;
}

.modalContainer {
  position: fixed;
  /* Stay in place */
  z-index: 99999999999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 16px;
}

/* Responsive Styles */
@media (min-width: 576px) {
  .modal-content {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .modal-content {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .modal-content {
    max-width: 500px;
  }
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.dropdown {
  background-color: white;
  display: flex;
  width: 400px;
  border: 1px solid #f4ecec;
  overflow: hidden;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  border-radius: 22px;
  padding: 10px 39px;
}

.dropdown:empty {
  border: none;
  height: 0px;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 8px 0;
  font-weight: bold;
  position: relative;
}

.dropdown-row::before {
  content: url(/static/media/from.63734e40.svg);
  position: absolute;
  left: -30px;
  top: 12px;
}

.flighttitleCloseBtn {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 35px;
}

.flighttitleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.flightPrice {
  display: flex;
  justify-content: space-around;
}

.flightPrice h3 {
  position: relative;
  left: 280px;
  top: 50px;
  color: white;
}

.flightPrice p3 {
  position: relative;
  left: 150px;
  top: 20px;
  font-weight: bold;
  color: white;
}

.flightname {
  display: flex;
}

.flightname hr {
  width: 200px;
  position: relative;

  top: 50px;
  border-top: 1px dashed red;
}

.flightname p {
  position: relative;
  top: 60px;
  left: 20px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.flightname p1 {
  position: relative;
  top: 60px;
  right: 20px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.timePrice {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 50px;
}

.timePrice p2 {
  position: relative;
  bottom: 20px;
  left: 200px;
  font-weight: bold;
  color: white;
}

.timePrice Button {
  position: relative;
  left: 200px;
  top: 10px;
  border: solid orange;
  background-color: #ff3c00;
  font-weight: bold;
  color: white;
}

@media only screen and (max-width: 760px) {
  .modalBackground {
    position: relative;
    bottom: 300px;
    right: 360px;
  }

  .dropdown {
    width: 210px;
  }

  .makeStyles-pageContent-27 {
    margin: 0 !important;
  }
}

.grey-field {
  background-color: #f0f0f0;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  padding: 9px 29px;
  height: 78px;
}

.grey-field span {
  color: #8e8e8e;
  font-weight: 600;
  margin-left: 10px;
}

.css-luel6v-MuiInputBase-root {
  color: #000000 !important;
  font-weight: 700 !important;
  background-color: unset !important;
}

.botharrow {
  position: relative;
}

.botharrow::after {
  content: url(/static/media/bothsidearrow.0a17adc0.svg);
  position: absolute;
  right: -25px;
  z-index: 999;
  top: 16px;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 12px;
}

.rounded-right {
  border-radius: 12px 0px 0px 12px !important;
}

.rounded-left {
  border-radius: 0px 12px 12px 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.MuiPaper-elevation1 {
  border-radius: 22px !important;
  background: #fff !important;
  box-shadow: 0px 0px 37.87297px 0px rgba(0, 0, 0, 0.17) !important;
}

.MuiSelect-select:focus {
  background-color: unset !important;
}

.select-person {
  padding: 0 !important;
}

.MuiSelect-select {
  padding: -6px 0px 0px !important;
}

.rounded-btn {
  background: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

/* .form-wrapper{
    box-shadow: 0px 0px 37.87297px 0px rgba(0, 0, 0, 0.17);
  } */

.MuiInput-underline:after {
  border-bottom: unset !important;
}

.grey-fieldtravel {
  background-color: #f0f0f0 !important;
  border-radius: 12px !important;
  border: 1px solid #d1d1d1 !important;
  padding: 9px 13px !important;
  height: 78px !important;
}

.grey-fieldtravel span {
  color: #8e8e8e;
  font-weight: 600;
}

.adut-increment {
  border-radius: 45px;
  background: #e9e9e9;
  padding: 4px 8px !important;
  /* margin-left: 60px !important; */
}

.flex {
  display: flex !important;
  align-items: center !important;
}

.incre-decre {
  min-width: 24px !important;
  height: 24px !important;
  background-color: white !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: black !important;
}

.justify-between {
  justify-content: space-between !important;
}

.MuiListItem-root.Mui-selected {
  background-color: transparent !important;
}

.adults-no {
  padding: 0 10px !important;
}

.done-btn {
  border-radius: 8px !important;
  background: #4052f3 !important;
  color: white !important;
  padding: 4 12px !important;
  width: 100% !important;
}

.select-font {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.loader-wrapper {
  max-width: 481px;
  margin: auto;
  height: 468px;
  border-radius: 40px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.loader-wrapper p {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.loader-wrapper h2 {
  margin: 0px;
  color: #000;
}

.contact-btn {
  background-color: #4052f3;
  border-radius: 8px;
  color: white;
}

*{
    font-family: 'Poppins', sans-serif;
}
.Top-Container
{
    background-image: url('/images/flights/top-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
}
.Top-Container h1{
    color: #fff;
    font-size: 80px;
   position: relative;
   top: 60px;
    
  }

/* .flight-main-container
{
 
   
} */
 

.flight-form-iner{
    margin: 100px;
    padding: 50px;
    border: 2px solid #ff3c00;

}
.main-card-container{
    margin: 30px;
}
.card-main-heading{ 
    font-size: 25px;
    color: #ff3c00;
    text-align: center;
}
.card-sub-heading{ 
    font-size: 20px;
   text-align: center;
}
.card-container{
    display: flex;
   margin-top: 30px;
  justify-content: space-between;
  
}
.f-card-1{
    height: 300px;
    width: 370px;
    background: url('/images/flights/cards/abuja.jpeg') center center/cover no-repeat;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-2{
    height: 300px;
    width: 370px;
    background: url('/images/flights/cards/accra.jpeg') center center/cover no-repeat;
    border: 2px solid #ff3c00;
    border-radius: 10px;
    
    background-position: top 40px right 0px;
}

.f-card-3{
    background: url('/images/flights/cards/colombo.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-4{
    background: url('/images/flights/cards/entebbe.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-5{
    background: url('/images/flights/cards/freetown.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.f-card-6{
    background: url('/images/flights/cards/kathmandu.jpeg') center center/cover no-repeat;
    height: 300px;
    width: 370px;
    border: 2px solid #ff3c00;
    border-radius: 10px;
}
.card-topbar{
    height: 50px;
    width: 100%;
    background: url('/images/card-top.png') center center/cover no-repeat;
}
.card-topbar h4 {
    
   position: relative;
   top: 5px;
   left: 10px;
   
   color: #fff;
}
.card-topbar p {
    margin-left: 25px;
    color: #fff;
    
 }
 .card-topbar h3 {
    font-size: 25px;
    position: relative;
    color: #fff;
    left: 120px;
    bottom:20px ;
 }
 .card-topbar img{
    height: 40px;
    width: 100px;
    position: relative;
    left: 240px;
    bottom: 60px;
 }
 .cards-button{
    background-color: rgb(112, 110, 110);
    height: 40px;
    width: 130px;
    font-size: 15px;
    font-weight: 700;
    position: relative;
    left: 140px;
    top: 180px;
    border: 1.5px solid #ff3c00;
    border-radius: 5px;
 }
 .cards-button:hover{
    
    background-color:#ff3c00 ;

    color: #fff;
    border: 1.5px solid rgb(112, 110, 110);
   
 }
 .cards-button a{
    text-decoration: none;
    

    color: #fff;
    
   
 }

 .below-cards{
   margin: 50px;
 }
 .below-cards h3{
    margin: 20px;
    text-align: center;
    font-size: 25px;
    
 }
.card2-container {
    margin: 90px;
    display: flex;
    justify-content: space-between;
}
.card2-1{
    background-color: #ff3c00;
    width: 300px;
    height: 300px;
    border-radius: 10px;
}
.card2-1 h4{
   
    font-size: 20px;
    position: relative;
    top: 10px;
    left: 45px;
    color: white;
}
.card2-1 p{
   
    margin: 20px;
    color: white;
}
.end-container{
    margin: 50px;
}
.end-container h3{
   text-align: center;
   font-size: 25px;
}
.end-container p{
    margin: 30px;

}


 @media screen and (max-width: 768px) {

    .Top-Container
{
   
    width: 100%;
    
    
}

    .Top-Container h1{
       
        font-size: 40px;
      
        
      }
  
    .flight-form-iner{
        margin: 20px;
        
    
    }
    .card-container{
        flex-direction: column;
        align-items: center;

      
    }
    .f-card-1{
        margin: 20px;
    }
    .f-card-2{
        margin: 20px;
    }
    .f-card-3{
        margin: 20px;
    }
    .f-card-4{
        margin: 20px;
    }
    .f-card-5{
        margin: 20px;
    }
    .f-card-6{
        margin: 20px;
    }

    .card2-container {
        
       flex-direction: column;
       align-items: center;
        
    }
    .card2-1{
      margin-top: 30px;
     
    }
    
}
*{
  font-family: 'Poppins', sans-serif;
}
.holiday-to-img {
    background-image: url('/images/holiday/holiday-top.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
   
    height: 45vh;
    width: 100%;
    
  }
  .holiday-to-img h1{
    font-size: 100px;
    color: #fff;
    padding: 100px;
  }
  .h-first-container{
    margin: 5%;
  }
  .h-first-container h3{
       
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
    color: #ff3c00;

  }
  .h-first-container p{
       
    text-align: center;

  }
  .h-second-conatainer{
    background-color: rgb(133, 133, 133);
    height: 600px;
    width: 100%;
    margin-bottom: 30px;
  }
  .h-card-container{
    display: flex;
    justify-content: space-around;
    
  }
  .bnumber{
    text-decoration: none;
    

    color: #fff;
  }
  .bnumber:hover{
    text-decoration: none;

    color: rgb(6, 6, 6);
  }

  .first-card{
    margin: 50px;
    background-color: #fff;
    height:490px;
    width: 300px;
    border-radius: 5px;
  }
  .card-image-1{
    height: 120px;
    width: 300px;
    background: url('/images/holiday/card-img-1.jpg') center center/cover no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-image-2{
    height: 120px;
    width: 300px;
    background: url('/images/holiday/card.jpeg') center center/cover no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-image-3{
    height: 120px;
    width: 300px;
    background: url('/images/holiday/card-img-3.jpeg') center center/cover no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-content{
    height: 280px;
    width: 300px;
    background-color: rgb(252, 252, 252);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
  }
  .card-content h4{
    font-size: 20px;
    text-align: center;
    color: #ff3c00;
    font-weight: bold;
  }
  .card-content h5{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }
  .card-content p{
    text-align: center;
  }
.card-content button{

  font-size: 20px;
  color: #fff;
  background-color: #ff3c00;
  border-radius: 5px;
  height: 35px;
  width: 160px;
  border: none;
  position: relative;
  left: 50px;
  top: 10px;
}
  .h-third-container{
    margin: 50px;
  }
  .h-third-container h4{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 20px;
    color: #ff3c00;
  }
.h-forth-container{

  height: 300px;
  width: 100%;
  background-color: yellow;
  margin-bottom: 50px;
}
.image-forth-container{
  height: 300px;
  width: 70%;
  background: url('/images/holiday/card2.jpg') center center/cover no-repeat;
 margin-left: 15%;
}
.image-forth-container h2{
 position: relative;
 top: 100px;
 left: 170px;
 color: #fff;;
 
}
.image-forth-container h5{
  position: relative;
 top: 110px;
 left: 300px;
 color: #fff;;
}
  @media screen and (max-width: 768px) {


    .holiday-to-img h1{
      font-size: 50px;
      
    }
    .h-first-container h3{
       
      
      font-size: 15px;
      font-weight: 700;
  
    }
    .h-card-container{
      flex-direction: column;
      align-items: center;
    }

    .h-second-conatainer{
      height: 1700px;
    }
    .desti {
     
      font-size: 50px;
     
    }
    .image-forth-container h2{
      position: relative;
      top: 100px;
      left: 20px;
      font-size: 15px;
       
     }
     .image-forth-container h5{
       position: relative;
      top: 110px;
      left: 20px;
      font-size: 12px;
      
     }

  }
*{
  font-family: 'Poppins', sans-serif;
}

  .contact-container{
    width: 100%;
    
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .contact-container h2{
     
    color: #ff3c00;
   font-size: 50px;
   margin: 50px;
   text-align: center;

  }
  .contact-container p{
      margin-bottom: 40px;
    font-family: 'Poppins', sans-serif; ;
  }

  .contact-container .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contact-container .content .left-side{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content .left-side::before{
    content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #ff3c00;
  }
  .content .left-side .details{
    margin: 14px;
    text-align: center;
  }
  .content .left-side .details i{
    font-size: 30px;
    color: #ff3c00;
    margin-bottom: 5px;
  }
  .content .left-side .details .topic{
    font-size: 18px;
    font-weight: 500;
  }
  .content .left-side .details .text-one,
  .content .left-side .details .text-two{
    font-size: 14px;
    color: #575656;
  }
  .contact-container .content .right-side{
    width: 50%;
    margin-left: 90px;
  }
  .email_contact{
   
      color: #575656;
        text-decoration: none;
        font-family: "bahnschrift";
      }
      .email_contact:hover{
        color: #ff3c00;
      }
      .phone_contact{
   
        color: #575656;
          text-decoration: none;
          font-family: "bahnschrift";
        }
        .phone_contact:hover{
          color: #ff3c00;
        }


    
  
  .content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #ff3c00;
  }
  .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
  }
  .right-side .input-box input,
  .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .right-side .message-box{
    min-height: 110px;
  }
  .right-side .input-box textarea{
    padding-top: 6px;
  }
  .right-side .button{
    display: inline-block;
    margin-top: 12px;
  }
  .button {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #636262;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .button:hover{
    background: #ff3c00;
  }
  
  @media (max-width: 950px) {
    .contact-container{
      width: 100%;
      padding: 30px 40px 40px 35px ;
    }
   

    

    .contact-container .content .right-side{
     width: 75%;
     margin-left: 55px;
  }
  }
  @media (max-width: 820px) {
    .container{
      margin: 40px 0;
      height: 100%;
    }
    .contact-container .content{
        flex-direction: column-reverse;
    }
   .container .content .left-side{
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     justify-content: center;
     flex-wrap: wrap;
   }
   .container .content .left-side::before{
     display: none;
   }
   .container .content .right-side{
     width: 100%;
     margin-left: 0;
   }
   .contact-container h2{
     
    font-size: 25px;
    text-align: center;

  }
  .contactus{
    font-size: 50px;
  }
  }
  
  
/* Sidebar Styling */
.sidebar {
  width: 220px;
  height: calc(100vh - 60px); /* Adjust height to exclude Topbar */
  background-color: #343a40;
  position: fixed;
  top: 60px; /* Start Sidebar right below the Topbar */
  left: 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  transition: width 0.3s ease-in-out;
  z-index: 500; /* Ensures Sidebar stays below the Topbar */
}

.sidebar.collapsed {
  width: 80px;
}

.sidebarWrapper {
  padding: 20px;
  overflow-y: auto;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 1.2rem;
  color: #adb5bd;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: opacity 0.3s ease-in-out, margin 0.3s ease-in-out;
}

.sidebar.collapsed .sidebarTitle {
  opacity: 0;
  margin: 0; /* Removes extra spacing in collapsed view */
}

.sidebarList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarListItem {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #e9ecef;
  font-size: 1rem;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.sidebarListItem:hover {
  background-color: #495057;
  color: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.sidebarIcons {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #adb5bd;
  transition: color 0.3s ease, transform 0.3s ease;
}

.sidebarListItem:hover .sidebarIcons {
  color: #1abc9c;
  transform: scale(1.1);
}

.sidebar.collapsed .sidebarIcons {
  margin-right: 0; /* Center icon in collapsed view */
}

.sidebar.collapsed .sidebarListItem {
  justify-content: center; /* Center text and icons when collapsed */
}

.sidebarListItem a {
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  transition: color 0.3s ease;
}

.collapseButton {
  background-color: #1abc9c;
  color: #ffffff;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: auto;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.collapseButton:hover {
  background-color: #16a085;
  transform: scale(1.05);
}

/* Adjustments for Collapsed Sidebar */
.sidebar.collapsed .sidebarListItem a {
  justify-content: center; /* Center link contents in collapsed state */
}

.sidebar.collapsed .sidebarWrapper {
  padding: 10px; /* Reduce padding when collapsed */
}

/* Topbar Styling */
.topbar {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  transition: background-color 0.3s ease;
}

.topbarWrapper {
  height: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 28px;
  color: #007acc;
  cursor: pointer;
  transition: color 0.3s ease;
}

.logo:hover {
  color: #005a99;
}

.topbariconContainer button {
  height: 35px;
  width: 100px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.topbariconContainer button:hover {
  background-color: #005a99;
  color: #ffffff;
  cursor: pointer;
}

/* Main Content Container */
.nav {
  display: flex;
  width: 100%;
}

.admin {
  flex: 4 1;
  padding: 50px;
  background-color: #f7f9fc;
}

/* User Container */
.userContainer {
  display: flex;
  justify-content: flex-end;
  margin: 50px;
}

.userContainer button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-decoration-color: #ffffff;
          text-decoration-color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.userContainer button:hover {
  background-color: #005a99;
}

/* Table Styling */
.a-table-Container {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.user-tabel {
  min-width: 650px;
}

.MuiTableCell-root {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 10px 20px;
}

.MuiTableHead-root {
  background-color: #007acc;
  color: #ffffff;
}

.MuiTableHead-root .MuiTableCell-root {
  color: #ffffff;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background-color: #f7f9fc;
}

.MuiTableBody-root .MuiTableRow-root:hover {
  background-color: #eef3f7;
}

/* Image Container */
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  height: 30px;
  width: 60px;
  border-radius: 5px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.img-container img:hover {
  transform: scale(1.1);
}

/* Button Styling */
.edit-btn,
.delete-btn {
  margin: 0 5px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-btn {
  color: #ffffff;
  background-color: #4caf50;
  border: none;
}

.edit-btn:hover {
  background-color: #388e3c;
}

.delete-btn {
  color: #ffffff;
  background-color: #e53935;
  border: none;
}

.delete-btn:hover {
  background-color: #c62828;
}

.navu{
  display: flex;
  width: 100%;

}
.uContainer{
    flex: 4 1;
}

.userContainer{

  margin: 20px;
}
.userContainer button{
   height: 40px;
   width: 150px;
  font-size: 15px;
  color: rgb(92, 79, 211);
font-weight: 600;
border: none;
border-radius: 3px;
}
.tableContainer{
  margin: 50px;
}
.edit-btn{
  size: 20px;
  color: black;
}
.delete-btn{
  width: 50px;
}
.navA {
  display: flex;
  width: 100%;
}
.aContainer {
  flex: 4 1;
}

.airline-Container {
  margin: 100px;
}
.airline-Container input {
  width: 300px;
  height: 40px;
  border-radius: 6px;
  margin-left: 20px;
}
.airline-Container h2 {
  text-align: center;
  margin-bottom: 50px;
}
.airline-Container button {
  height: 40px;
  width: 100px;
  font-size: 15px;
  color: rgb(92, 79, 211);
  font-weight: 600;
  border: none;
  border-radius: 6px;
  position: relative;
  left: 20px;
  top: 5px;
}
.disable {
  background-color: black;
}

.img-selector {
  margin-left: 70px;
  size: 20px;
}

.img-Container {
  height: 60px;
  width: 100px;
}
.cellImg {
  height: 60px;
  width: auto;
  margin-bottom: 5px;
}

.navL{
    display: flex;
   width: 100%;
  
  }
  .lContainer{
      flex: 4 1;
  }
  .destination-Container{
    margin: 20px;
    padding: 16px;
    background-color: white;
  }
  
  .destination-Container h1{
   
    margin-bottom: 50px;

  }
  .add-desti-form{

    margin: 10px;
  }
  .add-desti-form input{

    border-radius: 6px;
    
  }
  
  .a-label{
    margin-right: 70px;
    position: relative;
    left: 40px;
  }
  .ac-label{
    margin-left: 20px;
  }
  .city-label{
    margin-right: 90px;
    position: relative;
    left: 50px;
  }
  .country-label{
    margin-right: 20px;
    margin-left: 40px;
  }
  .destination-Container input{
    width: 300px;
    height: 40px;
    border-radius: 6px;
    margin-left: 20px;

  }
  .add-desti-btn{
    background-color: #1b1f1d;
    color: white;
    padding: 16px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 10%;
    opacity: 0.9;
    margin-left: 50px;
    position: relative;
    left: 100px;
   
  }
  .a-table-Container{
    margin: 30px;
  }
  .delete-btn{
    margin-top: 10px;
    width: 50px;
  }
.navC{
    display: flex;
   width: 100%;
  
  }
  .cContainer{
      flex: 4 1;
  }
.navS{
    display: flex;
   width: 100%;
  
  }
  .sContainer{
      flex: 4 1;
  }
  .seasionalFareC{
    text-align: center;
    margin-top: 20px;
  }
  .seasonal-placeholder{
    height: 35px;
    width: 200px;
    outline: none;
    border-radius: 5px;
  }
  .seasionalFareC button{
    height: 30px;
    width: 70px;
    margin-left: 10px;
    font-weight: bold;
  }

*{
    font-family: 'Poppins', sans-serif;
}
.policy-container{
    margin: 50px;
}
.policy-container h2{
    text-align: center;
    
    
}
.policy-container h4{
 
    font-size: 20px;
    margin-top: 20px;
    margin-top: 10px;
}

.term-container{
    margin: 50px;
}
.term-container h2{
    text-align: center;
    
}
.term-container h4{
 
    font-size: 20px;
    margin-top: 20px;
    margin-top: 10px;
}
.term-container p1{
    font-family: 'Poppins', sans-serif;
}
.navu{
    display: flex;
    width: 100%;
  
  }
  .rContainer{
      flex: 4 1;
  }
  
  .container {
    margin: 20px;
    padding: 16px;
    background-color: white;
  }
  .f-container p{
    margin-left: 50px;
    font-weight: 600;
  }
  .r-email-label{
    margin-left: 80px;
  }
  .r-password-label{
    margin-left: 40px;
  }
  .f-container input{
    width: 200px;
  }
  
  /* Overwrite default styles of hr */
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  
  /* Set a style for the submit button */
  .registerbtn {
    background-color: #1b1f1d;
    color: white;
    padding: 16px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 10%;
    opacity: 0.9;
    margin-left: 50px;
    position: relative;
    left:300px;
  }
  
  .registerbtn:hover {
    opacity: 2;
  }
  
  /* Add a blue text color to links */
  a {
    color: dodgerblue;
  }
  
  
.logContainer{
   width: 600px;
   height: 800px;
   position: relative;
   left: 300px;
}

.login-container {
  margin: 20px;
  padding: 16px;
  background-color: white;
}
.login-container span{
    position: relative;
    left: 180px;
    color: red;
    
  }
.login-container p{
  margin-left: 50px;
  font-weight: 600;
}
.login-email-label{
  margin-left: 80px;
}
.r-password-label{
  margin-left: 70px;
}
input[type=email], input[type=password] {
  width: 400px;
  padding: 15px;
  margin-left: 20px;
  border-radius: 6px;
  display: inline-block;
  border: none;
  background: #f1f1f1;
  position: relative;
  left: 50px;
}
input[type=email]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
  
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.loginbtn {
  background-color: #1b1f1d;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 20%;
  opacity: 0.9;
  margin-left: 50px;
  position: relative;
  left:300px;
}

.registerbtn:hover {
  opacity: 2;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Main Container */
.navaddf {
  display: flex;
  width: 100%;
}

.add-f-Container {
  flex: 4 1;
  padding: 40px;
  background-color: #f7f9fc;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

/* Form Container */
.addf-form-Container {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.addf-form-Container p {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.addf-form-Container label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-top: 15px;
}

.addf-form-Container input,
.addf-form-Container select {
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 5px 20px;
  font-size: 14px;
  color: #555;
  transition: border-color 0.3s ease;
}

.addf-form-Container input:focus,
.addf-form-Container select:focus {
  border-color: #007acc;
  outline: none;
}

.addf-form-Container select {
  background-color: #f9f9f9;
  -webkit-appearance: none;
          appearance: none;
}

/* Button Styling */
.add-flight-btn {
  display: block;
  width: 150px;
  margin: 20px auto 0;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-flight-btn:hover {
  background-color: #005a99;
  transform: scale(1.05);
}

/* Label Styling */
.addf-form-Container label {
  margin-left: 20px;
  font-size: 14px;
}

/* Input and Select Specific Styling */
.s-origin,
.s-destination,
.s-airline,
.s-airline-c,
.i-adultfare,
.i-childfare,
.i-infantfare,
.i-flightduration,
.i-flightstop,
.i-airline-logo {
  width: calc(100% - 40px);
  margin: 10px 20px;
}

.i-adultfare-label,
.i-childfare-label,
.i-infantfare-label,
.i-flightduration-label,
.i-flightstop-label,
.airline-logo-lable {
  display: inline-block;
  width: 100%;
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  color: #555;
}

.i-flightstop {
  width: calc(100% - 40px);
}

/* Image Upload Section */
.img-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-airline-logo {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: 20px;
}

/* Time Inputs */
.i-flightstop,
.i-flightstop[type="time"] {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 10px 20px;
  width: calc(100% - 40px);
  transition: border-color 0.3s ease;
}

.i-flightstop:focus {
  border-color: #007acc;
}

/* General Hover Effects */
.addf-form-Container input:hover,
.addf-form-Container select:hover {
  border-color: #007acc;
}

/* Headings */
.add-f-Container h1 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}
.addf-form-Container hr {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .addf-form-Container input,
  .addf-form-Container select {
    width: calc(100% - 20px);
    margin: 5px 10px;
  }

  .add-flight-btn {
    width: 100%;
    margin: 15px 0;
  }
}

/* 

  
.from-container{
  position: relative;
  right: 120px;
}

.Travel-To{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 125px;
  top: 40px;
}
.To-container{
  position: relative;
  right: 50px;
}
.Flight-Class{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 60px;
  
}

.Flight-Class p{
  
  position: relative;
  left: 30px;
  
  
}
.class-name-container{
  position: relative;
  right: 200px;
}
.Adult-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 80px;
}
.Adult-Fare p1{
 position: relative;
  right: 210px;
 
}
.Child-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 100px;
}
.Child-Fare p1{
  
  position: relative;
  right: 210px;
  
}
.Infant-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 120px;
}
.Infant-Fare p1{
 position: relative;
  right: 210px;
  
}
.Total-Fare{
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 210px;
  top: 140px;
}
.Total-Fare p1{
  
  position: relative;
  right: 200px;
  font-size: 20px;
  color: #ff3c00;
}

 .hr-Container hr{
  border: .5px solid #ff3c00 ;
 }

 .Input-Container{
  text-align: center;
 }
 .Input-Container h3{
  font-size: 30px;
  color: #ff3c00;
 }
 .sub-Heading-Container{
  margin-top: 20px;

 }
 .sub-Heading-Container p{
  font-size: 15px;
  
 }

 .F-name-Textfield-Container{
  display: flex;
 }



 .F-name-Textfield{
  width: 500px;
  position: relative;
  left: 210px;
  
 }

 
 .L-name-Textfield{
  width: 500px;
  position: relative;
  left: 305px;
  
 }

 .phone-Number-Textfield{
  width: 500px;
  position: relative;
  left: 305px;
 }
 .email-above-p{
  margin-top: 20px;
  font-size: 12px;
 }
 .email-above-hr hr{
  border: 0.5px solid #ff3c00 ;
 }
 .After-hr-Container p1{
         font-size: 12px;
 }
 .email-container{
  width: 500px;
  position: relative;
  left: 305px;
 }
 .Checkbox-Container{
  display: flex;
 }
 .Checkbox-Container p{
  font-size: 12px;
  margin-top: 12px;
 }
 .last-btn button{
  background-color: #ff3c00;
  width: 200px;
  text-align: center;
position:relative ;
right: 30px;
border-radius: 6px;
height: 30px;
border: none;
font-weight: bold;
color: #fff;


 }
.modal-Container hr{
  border: 0.5px solid #dadada ;
} */

.d-flex {
  display: flex;
}

.flight-book-bg {
  border-radius: 15px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
}

.bookFlight-Main {
  width: 100%;
  background-color: whitesmoke;
}

.Card-Main-Container {
  background-color: white;
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */

  grid-gap: 10px;

  gap: 10px;
}
.grid-container-flight {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
}
.grid-container-flight-center {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
}

.column-3 {
  grid-column: span 3; /* Set the width of this column to 3 columns */
}
.column-4 {
  grid-column: span 4 !important; /* Set the width of this column to 3 columns */
}
.column-5 {
  grid-column: span 5 !important; /* Set the width of this column to 3 columns */
}
.column-2 {
  grid-column: span 2; /* Set the width of this column to 3 columns */
}
.column-7 {
  grid-column: span 7; /* Set the width of this column to 3 columns */
}
.column-6 {
  grid-column: span 6 !important; /* Set the width of the second column to 6 columns */
}
.column-8 {
  grid-column: span 8; /* Set the width of this column to 3 columns */
}
.column-9 {
  grid-column: span 9; /* Set the width of this column to 9 columns */
}
.column-12 {
  grid-column: span 12 !important;
}
.Ocolor {
  color: #fc3c00;
}

.p-30 {
  padding: 30px;
}

.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fs-30 {
  font-size: 30px;
}
.pText {
  color: #727272;
}

.align-items-center {
  align-items: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ms-10 {
  margin-left: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.p-12 {
  padding: 12px;
}
.p-10 {
  padding: 10px 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-40 {
  padding: 40px !important;
}
.ps-10 {
  padding-left: 10px !important;
}
.p-20 {
  padding: 20px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-30 {
  margin-top: 30px !important ;
}
.my-20 {
  margin: 20px 0 !important;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mt-100 {
  margin-top: 100px !important;
}
.justify-content-between {
  justify-content: space-between;
}

.form-bg {
  border-radius: 15px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
}
.text-center {
  text-align: center !important;
}
.text-black {
  color: black !important;
}
.text-white {
  color: #fff !important;
}
.fs-18 {
  font-size: 18px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-20 {
  font-size: 20px;
}
.fs-28 {
  font-size: 28px !important;
}
.myInputs {
  border-radius: 8px !important;
  border: 1px solid #d1d1d1 !important;
  background: #f0f0f0 !important;
}
.subBtn {
  border-radius: 8px !important;
  background-color: var(--2ndry, #4052f3) !important;
  color: white !important;
  border: none !important;
}
.orangeBtn {
  border-radius: 8px !important;
  background-color: #fc3c00 !important;
}

.flight-card-bg {
  border-radius: 15px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
}
.flight-Cards-Container {
  overflow-y: hidden !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.mb-12 {
  margin-bottom: 12px !important;
}

.flight-logo {
  width: 100% !important;
  height: auto !important;
  padding: 11px 43px;
}
.btnGreen {
  border-radius: 6px;
  background: #31aa3d;
  border: none !important;
  padding: 8px 15px;
}
.py-27 {
  padding: 27px 0 !important;
}
.py-10 {
  padding: 10px 10px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important ;
}
.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px !important;
}
.ml-10 {
  margin-left: 10px;
}
.no-wrap {
  text-wrap: nowrap;
}
.border-end {
  border-right: 1px solid #dadada !important;
}
.px-15-10 {
  padding: 10px 15px !important;
}

.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}

.btnCall {
  border-radius: 9px;
  background: rgba(64, 82, 243, 0.21);
  border: unset !important;
}
.pb-32 {
  padding-bottom: 32px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.pl-23 {
  padding-left: 23px !important;
}
.justify-content-center {
  justify-content: center;
}

.adultBg {
  border-radius: 9px;
  background: #efefef;
}
.w-100 {
  width: 100% !important;
}
.h-50 {
  height: 50px !important;
}
.p-20 {
  padding: 20px !important;
}
.css-ypiqx9-MuiDialogContent-root {
  padding: 0px !important;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.Card-Left-Container {
  text-align: center;
  grid-column: span 3;
  padding: 27px 0;
  border-right: 1px solid #dadada !important;
}
.Card-Center-Container {
  padding: 29px 6px !important;
  grid-column: span 6;
  border-right: 1px solid #dadada !important;
}
.Card-Right-Container {
  text-align: center;
  grid-column: span 3;
  padding-bottom: 32px !important;
  padding: 15px !important;
}
.dotted-line {
  width: 200px;
}
.ml-8 {
  margin-left: 8px !important;
}
.mr-73 {
  margin-right: 73px;
}
.mr-22 {
  margin-right: 22px;
}
.ml-26 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 10px !important;
}

.flight-tickets {
  grid-column: span 3;
}
.flight-detail {
  grid-column: span 9;
  padding: 0 !important;
}
.From-Stop-To-Caontainer {
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.Center-Departure {
  text-align: center;
  grid-column: span 3;
}
.airplane-line {
  grid-column: span 6;
}
.Center-Travel-To {
  grid-column: span 3;
  text-align: center;
}

.best {
  grid-column: span 4;
  border-right: 1px solid #dadada !important;
}
.bestBtn {
  border-radius: 10px;
  width: 86%;
}
.cheapestBtn:active {
  border-radius: 10px;
}
.cheapest {
  grid-column: span 4;
  border-right: 1px solid #dadada !important;
}
.quickest {
  grid-column: span 4;
}
.MuiCheckbox-root {
  color: #fc3c00 !important;
  border-color: none !important;
}
@media screen and (max-width: 1200px) {
  .dotted-line {
    width: 340px;
  }

  .grid-container-flight {
    flex-direction: row;
  }
  .mr-68 {
    margin-right: 65px;
  }
  .ml-26 {
    margin-left: 26px;
  }

  .Card-Left-Container {
    text-align: center;
    order: 2;
    grid-column: span 6;

    padding: 27px 0;

    border-right: 1px solid #dadada !important;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .Card-Right-Container {
    text-align: center;
    order: 3;
    grid-column: span 6;
    padding-bottom: 32px !important;
    padding: 15px !important;
  }
  .Card-Center-Container {
    padding: 20px !important;
    order: 1;
    border-right: unset !important;
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
  }
}

@media screen and (max-width: 991px) {
  .firstNamecol {
    grid-column: span 6 !important;
  }
  /* .progresscol{
    grid-column:span 6 !important;
  } */

  .seat-icons {
    margin-top: 90px !important;
  }
  .best-option {
    grid-column: span 6 !important;
  }
  .your-booking {
    grid-column: span 12 !important;
  }
  .grid-container {
    flex-direction: row;

    grid-gap: 10px;

    gap: 10px;
  }
  .flight-tickets {
    grid-column: span 12;
    order: 2;
  }
  .flight-detail {
    grid-column: span 12;
    padding: 0 !important;
  }
  .column-6 {
    grid-column: span 12 !important;
  }
  .column-9 {
    grid-column: span 12 !important;
  }
  .seat-icons {
    position: relative !important;
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .progressBar {
    width: 150px !important;
    font-size: 14px !important;
  }
  .firstNamecol {
    grid-column: span 12 !important;
  }
  .confirmseat {
    display: block !important;
  }
  .MuiGrid-grid-xs-1 {
    flex-grow: 0 !important;
    max-width: 12.333333% !important;
    flex-basis: 12.333333% !important;
  }
  .seat-icons {
    margin-top: 70px !important;
  }
  .best-option {
    grid-column: span 12 !important;
  }
  .sikness {
    grid-column: span 12 !important;
  }
  .bestBtn {
    border-radius: 10px;
    width: 100%;
  }
  .grid-container-flight-center {
    flex-direction: row;
  }

  .Center-Departure {
    text-align: center;
    grid-column: span 12;
  }
  .airplane-line {
    grid-column: span 12;
  }

  .Center-Travel-To {
    grid-column: span 12;
    text-align: center;
  }

  .dotted-line {
    width: 280px;
  }

  .grid-container-flight {
    flex-direction: row;
  }
  .mr-68 {
    margin-right: 65px;
  }
  .ml-26 {
    margin-left: 26px;
  }

  .Card-Left-Container {
    text-align: center;
    order: 1;
    grid-column: span 12;

    padding: 27px 0;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .Card-Right-Container {
    text-align: center;
    order: 3;
    grid-column: span 12;
    padding-bottom: 32px !important;
    padding: 15px !important;
  }
  .Card-Center-Container {
    padding: 10px !important;
    order: 2;
    border-right: unset !important;
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
  }
}
.ps-20 {
  padding-left: 24px !important;
}

@media screen and (max-width: 580px) {
  .progressBar {
    /* width: 100px !important; */
    font-size: 12px !important;
    text-wrap: nowrap;
  }
  .best {
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .cheapest {
    grid-column: span 12;
    border-bottom: 1px solid #dadada !important;
    border-right: unset !important;
  }
  .quickest {
    grid-column: span 12;
  }
}

.modal {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  z-index: 2;
}

/* checkout css  */
.css-eglki6-MuiLinearProgress-root {
  background-color: #d9d9d9 !important ;
}
.pBar {
  height: 10px !important;
  border-radius: 10px !important;
}
.your-booking-bg {
  border-radius: 12px;
  background: #eef0ff;
}

.dotted-border {
  border-bottom: 2px dotted #cacfff;
}
.greenColor {
  color: #458f2a;
}

.our-guarantees {
  border-radius: 15px !important;
  border: 1px solid #dadada !important;
  background: #fff !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07) !important;
}

.border-bottom {
  border-bottom: 1px solid #dadada;
}

.bordernone {
  border-bottom: none !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.addanotherbtn {
  border-radius: 76px !important;
  background: #fc3c00 !important;
}
.one-bg {
  background-color: #d9d9d9 !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 50% !important;
}

.blue {
  color: #4052f3 !important;
}
.bg-blue {
  background-color: #4052f3 !important;
}
.bg-cross {
  /* background-color: #4052F3 !important; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFGSURBVHgBpVLbbYQwEMQgwS8lXAmUAB0kn7yUo4IkFdylglwqMBGvT9IB6SB0EErgGwnILDKRQ3wKUlZC2Lsz41l7WVEUnxpiGAYvSZJO2xGc84NpmjWWti5ylGiosJPcYOnQXkd4+Ld7RCQyYVriMlGwJdUenxeGYSuT0aozz3PDGLOJLFrumQzK8zwF4I5E8E+CIHijfJZlLvY1kSHyGkXRceWwrU2AL7B2vxQZW4AgpaL8AmcPMv6XgBA5Q+Qk56Zpeorj+LzFKgUoyrJscLIrnLyjHU+F01XJqqpOK1m04FJOhWWKk59BWPocxzExDIOWXDi5wMnjVQGcwtHrEQI97kB+hRuQuXjCFBeZ/BCgObAsqyarRAZw/xxshqij6fJ9v9PUd3OAw+9JhMitDvLHHjIF1cToE8ahg9dX+JN8ReT/8QV947CSMjz9TQAAAABJRU5ErkJggg==) !important;
  background-color: #d9d9d9 !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.light-text {
  color: #b7b7b7 !important;
}
.select-flight-bg {
  border-radius: 7px !important;
  background: #f6f6f6 !important;
}

.hannover-bg {
  border-radius: 7px !important;
  border: 1px solid #f0f0f0 !important;
  background: #fff !important;
}

.seats-checkbox-bg {
  border-radius: 7px 7px 0px 0px !important;
  background: #f6f6f6 !important;
}
.no-resize {
  resize: none !important;
}

.seat-icons {
  margin-top: 110px !important;
}

.bg-orange {
  background-color: #fc3c00 !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAWCAYAAAArdgcFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC0SURBVHgB7ZXhCcIwFIQv0P9mAx2hI7hZ4yRdxQ0coXYC3SBe9KIPERHy1D/94GhC4N1rIPeQcz7lBxN1oDZwoKOi1mdqD0/Uce1+gCOl8yNuHc9UogFCCDt4oHsetU76gxEesFBPRbP3NXhhuBh8ZDBh4R3KHteXbIunb0TFXwx+9w5KlFBbePBsYPaxQyOM52tMk0HfWUcreGE6roNn3dy5Ctc7L6PyHt/NxTULehWN9uwCUPXsd6+WZu8AAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.emilType input[type="email"] {
  padding: 18.5px 14px !important;

  margin-left: 0px;
  left: 0 !important;
  border-radius: 6px;
  border: none;
  background: #f1f1f1;
  width: 100% !important;
}

.p-phone {
  padding: 18.5px 14px !important;
}

.bestBtn {
  padding: 36px 19px !important;
}

.tag-msg {
  font-size: 12px;
}

.pText {
  font-size: 15px;
  font-weight: 700;
}

.returnimg {
  transform: rotate(180deg) !important;
}
.btnCall {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

* {
    font-family: 'Poppins', sans-serif;
}

.cards {
    background-color: #fff;
}

h1 {
    text-align: center;
    margin-top: 40px;
}

.end-img-container {
    height: 400px;
    width: 1100px;
    background: url('/images/Holiday.png') center center/cover no-repeat;
}

.cards__container {
    padding: 1rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items {
    margin-bottom: 24px;
}

.cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    margin-left: 10px;
    right: 8px;
    padding: 4px 18px;
    top: 10px;
    max-width: calc((100%) - 60px);
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    background-color: #ff3c00;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif;
    
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    border-radius: 20px;
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item__info {
    padding: 20px 30px 30px;
    margin-top: -122px;
    z-index: 9999999999;
}

.cards__item__text {
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
}

.card-middle {
    width: 100%;
    height: 700px;
    padding: 50px;
    display: flex;
}

.card-middle-left {
    height: 600px;
    width: 500px;
}

.card-middle-left h2 {
    margin-bottom: 20px;
    font-size: 30px;
    color: #ff3c00;
}

.card-middle-left h3 {
    margin-top: 70px;
    margin-bottom: 20px;
    font-size: 25px;
    color: #ff3c00;
}

.card-middle-left p1 {
    font-size: 20px;
    position: relative;
    bottom: 5px;
    left: 7px;
}

.icons {
    color: #ff3c00;
}

.card-middle-right {
    background: url('/images/home-top.jpg') center center/cover no-repeat;
    height: 600px;
    width: 700px;
    margin-left: 30px;
}

.card-middle-right h3 {
    color: #ff3c00;
    position: relative;
    top: 550px;
    right: 250px;
}

.card-middle-right img {
    max-width: 100%;
    max-height: 100%;
}


/* this section below the why our customers love us */

.main-box-container h1 {
    color: #0f0f0f;
}

.home-box-container {
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    font-family: 'Poppins', sans-serif;
}

.box {
    position: relative;
    width: 350px;
    height: 350px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    border-radius: 4px;
    margin: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
}

.box .icon {
    position: relative;
    width: 70px;
    height: 70px;
    color: #fff;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 700;
    transition: 1s;
}

.box:nth-child(1) .icon {
    box-shadow: 0 0 0 0 #535353;
    background: #535353;
}

.box:nth-child(1):hover .icon {
    box-shadow: 0 0 0 400px #535353;
    background: #535353;
}

.box:nth-child(2) .icon {
    box-shadow: 0 0 0 0 #ff3c00;
    background: #ff3c00;
}

.box:nth-child(2):hover .icon {
    box-shadow: 0 0 0 400px #ff3c00;
    background: #ff3c00;
}

.box:nth-child(3) .icon {
    box-shadow: 0 0 0 0 #0f0f0f;
    background: #0f0f0f;
}

.box:nth-child(3):hover .icon {
    box-shadow: 0 0 0 400px #0f0f0f;
    background: #0f0f0f;
}

.box .content {
    position: relative;
    z-index: 1;
    transition: 0.5s;
}

.box:hover .content {
    color: #fff;
}

.box .content h3 {
    font-size: 20px;
    margin: 10px;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.box .content p {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
    .d-lg-block{
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
    .card-middle-right {
        display: none;
    }
    .card-middle img {
        height: 300px;
        width: 800%;
        margin: 0px;
        position: relative;
        right: 100px;
    }
    .cards__container h2 {
        margin-top: 30px;
        font-size: 20px;
        text-align: center;
    }
    .card-middle-left h2 {
        font-size: 23px;
    }
    .card-middle-left h3 {
        font-size: 23px;
    }
    .card-middle-left-p1 p1 {
        font-size: 18px;
    }
    .below-card h2 {
        font-size: 20px;
        margin-bottom: 50px;
        text-align: center;
    }
    .box-container {
        display: flex;
        flex-direction: column;
    }
    .end-img-container {
        display: none;
    }
}

.story-tag {
    font-size: 15px;
    color: #8E8E8E;
    font-weight: 600;
}

.related-blogs{
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    background: #F9F9F9;
    padding: 20px;
    border-radius: 20px;
}

.related-blogs .date{
    color: #FF5B4A;
    font-weight: 700;
    font-weight: 14px;
}

.related-blogs .blog-title{
    color: #2F3045;
    font-size: 18px;
    font-weight: 600;
}
.seemore{
    color: #2F3045;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}
.mb{
    margin-bottom: 25px;
}
.img-fluid{
    width: 100%;
  height: auto;
}
.main-blog{
    position: relative;
}
.main-info{
position: absolute;
bottom: 50px;
left: 20px;
}
.text-white{
    color: #fff;
}
a{
    text-decoration: none;
}

.py-60{
    padding: 60px 0px;
}

.fs-14{
    font-size: 14px;
}

.bg-myblue{
    position: relative;
}
.blue-section{
    padding: 80px 0px;
    background:url(/static/media/springbg.bd531d7b.png);
    
    background-color: #4052F3;
}

.flight-banner{
    position: absolute;
    bottom: 0;
}

.red-btn{
    background-color: #FC3C00;
    padding: 20px 10px;
    border-radius: 8px;
}
.mb-0{
    margin-bottom: 0 !important;
}
.my-10{
    margin: 10px 0px;
}

.me-10{
    margin-right: 10px;
}
.rounded-8{
    border-radius: 8px;
}


.d-none{
    display: block;
}
* {
  font-family: "Poppins", sans-serif;
}

/* .topnav{
  
    width: 100%;
    background: url('/images/top_bar.png') center center/cover no-repeat ;
    display: flex;
    
    height: 50px;
    padding-top: 8px;
    justify-content: space-between;
    color: rgb(229, 226, 226);
    
  } */

/* .pcontainer{
   
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    border-left: 30px solide green;
    
    
  } */

.phone {
  margin-right: 230px;
  display: flex;
  color: rgb(255, 255, 255);
  font-family: "bahnschrift";
}

/* .econtainer{
  
    border-bottom: 50px solid red;
    border-right: 30px solid transparent;
    height: 10px;
    width: 300px;
    
  } */

.email {
  display: flex;
  margin-left: 30px;
  color: rgb(255, 255, 255);
  font-family: "bahnschrift";
}

.email a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-family: "bahnschrift";
}

.email a:hover {
  color: rgb(0, 0, 0);
}

.fb {
  size: 20px;
  height: 30px;
  width: 20px;
  margin-right: 20px;
  color: green;
  margin-left: 10px;
}

.insta {
  size: 20px;
  height: 30px;
  width: 30px;
  margin-right: 20px;
}

.wts {
  size: 20px;
  height: 30px;
  width: 30px;
  margin-right: 50px;
}

.our_social {
  display: flex;
  margin-left: 10px;
}

.social-icons-top {
  display: flex;
  font-size: 20px;
  margin-left: 40px;
}

.social-links a {
  display: inline-block;
  height: 37px;
  width: 37px;
  background-color: #ff3c00;
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
  margin-left: 7px;
}

.social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

/* .acontainer{
   
    background-color: rgb(194, 194, 194);
    width: 25%;
    margin-bottom: 5px;
    
    
  }
  .scontainer{
   
    background-color: rgb(2, 2, 2);
    width: 25%;
    margin-bottom: 5px;
    
    
  } */

@media screen and (max-width: 768px) {
  .topnav {
    display: none;
  }
}

.py-60 {
  padding: 60px 0px;
}

.slider-wrapper {
  background-image: url(/static/media/greybg.ad439218.png);
  background-color: #f3f3f3;
}

.slider-card {
  text-align: center;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
}

.slider-card .book-btn {
  margin: 54px 0px;
  border-radius: 7.973px;
  background: #000;
  font-size: 13px 29px;
  font-weight: 700;
  padding: 10px 20px;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slider-card img {
  margin-left: auto;
  margin-right: auto;
}

.slider-card {
  height: 400px;
}

.slick-next:before {
  content: url(/static/media/next.523b7d2e.svg);
}

.slick-prev:before {
  content: url(/static/media/prev.7bd78912.svg);
}

.slick-prev {
  left: -76px !important;
}

.slick-prev {
  left: -76px !important;
}

.slick-next {
  right: -43px;
}

.slider-heading {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.section-heading {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 40px;
}

/* Hide navigation arrows for screens with a max-width of 768px */

@media (max-width: 1200px) {
  .slick-arrow {
    display: none !important;
  }
  .section-heading {
    font-size: 18px;
  }
}

.py-60{
    padding: 60px 0px;
}

.text-center{
    text-align: center;
}

.grey-card-wrapper{
    background-color:#EEE ;
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    padding: 20px;
}


.grey-card-wrapper h4{
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0px;
}
.grey-card-wrapper p{
    font-weight: 500;
    line-height: 35px;
}
h2{
    margin-bottom: 20px ;
}
.d-none{
    display: none;
  }

@media screen and (min-width: 992px) {
    .d-lg-block {
      display: block;
    }
  }


 
.Review-card {
    background-color: #fff;
}

.py-60 {
    padding: 60px 0px;
}

.d-flex {
    display: flex;
    align-items: center;
}


.review-section {
    background-color: #F5F5F5;
    ;
}

.Review-card {
    padding: 20px;
    border-radius: 6px;
}

.name {
    font-weight: 700;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.card-header {
    margin-bottom: 10px;
}

.card-header img {
    margin-right: 10px;
}

.stars {
    margin-top: 10px;
}



.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 26px;
    position: absolute;
    top: 0;
    background-color: #D9D9D9;
    width: 49px;
    border-radius: 20px;
    height: 7px;
    content: '' ;
    text-align: center;
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0px 25px;
    padding: 0px;
    cursor: pointer;
}

.slick-dots{
    bottom: -43px;
}
.slick-dots li.slick-active button:before{
    background-color: #4052F3;
}

.slick-center .Review-card{
    background-color: #4052F3;
    color: #fff;
}
.slick-center .qouts{
    filter: brightness(0) invert(1);

}

.view-all-btn{
    background-color: #4052F3;
    padding: 11px 28px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border: #4052F3;
    margin-top: 55px;
}
.text-center{
    text-align: center;
}
/* video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
} */

.hero-container {
  background: url("/images/slider/slide1.png") no-repeat;
  background-size: 100% 100%;
  height: 40vh;
  width: 100%;
  border-bottom-left-radius: 250% 160px;
  border-bottom-right-radius: 250% 160px;
  text-align: left;
  padding: 17px 59px;
}

.hero-container > h1 {
  color: #fff;
  font-size: 40px;
  margin-top: -200px;
  /* margin-right: 800px; */
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  /* margin-right: 765px; */
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
}

@media only screen and (max-width: 1920px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 30px;
    /* margin-left: 40px; */
  }
  .hero-container > p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .hero-container h1 {
    font-size: 24px !important;
    margin-top: -90px;
  }
  .hero-container {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: 150px;
    display: none;
  }
  .hero-container > p {
    font-size: 30px;
    display: none;
  }
  /* .hero-container {
    display: none;
  }
   */
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .hero-container {
    height: 40vh;
  }
}

h1 {
  text-align: left !important;
}

.custom-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .custom-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .custom-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .custom-container {
    width: 1170px;
  }
}

.text-white {
  color: white;
}

.fly-tag {
  background-color: #4052f3;
  border-radius: 21px;
  color: white;
  font-weight: 800;
  font-size: 14px;
  padding: 3px 17px;
}

h1 {
  margin-top: unset;
  margin-top: 5px;
  font-size: 60px;
}

.mt-60 {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .fly-tag {
    display: none;
  }
}
/* this is a form section*/

