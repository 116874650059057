*{
    font-family: 'Poppins', sans-serif;
}
.policy-container{
    margin: 50px;
}
.policy-container h2{
    text-align: center;
    
    
}
.policy-container h4{
 
    font-size: 20px;
    margin-top: 20px;
    margin-top: 10px;
}
