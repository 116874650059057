.term-container{
    margin: 50px;
}
.term-container h2{
    text-align: center;
    
}
.term-container h4{
 
    font-size: 20px;
    margin-top: 20px;
    margin-top: 10px;
}
.term-container p1{
    font-family: 'Poppins', sans-serif;
}