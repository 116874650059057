.Review-card {
    background-color: #fff;
}

.py-60 {
    padding: 60px 0px;
}

.d-flex {
    display: flex;
    align-items: center;
}


.review-section {
    background-color: #F5F5F5;
    ;
}

.Review-card {
    padding: 20px;
    border-radius: 6px;
}

.name {
    font-weight: 700;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.card-header {
    margin-bottom: 10px;
}

.card-header img {
    margin-right: 10px;
}

.stars {
    margin-top: 10px;
}



.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 26px;
    position: absolute;
    top: 0;
    background-color: #D9D9D9;
    width: 49px;
    border-radius: 20px;
    height: 7px;
    content: '' ;
    text-align: center;
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0px 25px;
    padding: 0px;
    cursor: pointer;
}

.slick-dots{
    bottom: -43px;
}
.slick-dots li.slick-active button:before{
    background-color: #4052F3;
}

.slick-center .Review-card{
    background-color: #4052F3;
    color: #fff;
}
.slick-center .qouts{
    filter: brightness(0) invert(1);

}

.view-all-btn{
    background-color: #4052F3;
    padding: 11px 28px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border: #4052F3;
    margin-top: 55px;
}
.text-center{
    text-align: center;
}